import { Component, Inject, OnInit } from '@angular/core';
import { InputComponent } from '../../../common/input/input.component';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TextareaComponent } from '../../../common/textarea/textarea.component';
import { SelectComponent } from '../../../common/select/select.component';
import { categoriasBens } from '../../../common/categorias-bens';
import { BackendService } from '../../../services/backend.service';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { Bem } from '../../../models/Bem';
import { CommonModule } from '@angular/common';


@Component({
  selector: 'app-adicionar-bem',
  standalone: true,
  imports: [InputComponent, TextareaComponent, ReactiveFormsModule, SelectComponent, MatDialogModule, CommonModule],
  templateUrl: './adicionar-bem.component.html',
  styleUrl: './adicionar-bem.component.scss'
})
export class AdicionarBemComponent implements OnInit {

  constructor(private builder: FormBuilder, private svc: BackendService, private router: Router, @Inject(MAT_DIALOG_DATA) private bem: Bem, private dialog: MatDialog) { }

  ngOnInit(): void {
    const bem = this.bem ? this.bem : new Bem();
    this.initializeForm(bem);
    console.log(categoriasBens)
  }
  options = categoriasBens;
  form: FormGroup;

  private initializeForm(bem: Bem) {
    this.form = this.builder.group({
      id: [bem.id],
      nome: [bem.nome, [Validators.required]],
      valor: [bem.valor, [Validators.required]],
      descricao: [bem.descricao],
      categoria: [bem.categoria || 'outros']
    });
  }

  adicionarBem() {
    const endpoint = this.form.get('id')?.value ? 'atualizar-bem' : 'adicionar-bem';
    this.svc.call(endpoint, this.form.getRawValue()).subscribe({
      next: r => { this.dialog.closeAll() },
      error: e => { console.log(e) }
    })
  }

  removerBem() {
    this.svc.call('remover-bem', {id: this.form.get('id')?.value}).subscribe({
      next: r => {this.dialog.closeAll() },
      error: e => { }
    })
  }

}
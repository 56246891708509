<ng-container *ngIf="dividendos">

    <div class="grid espaco-3 grid-cards mt-4 w-full mb-2">
        <app-ativos-card topTitle="Total Proventos" [topValue]="dividendos[corretoraSelecionada]['total']"
            bottomValue="5" bottomText="variação hoje" />
        <app-ativos-card topTitle="Total Proventos (Ações)" [topValue]="dividendos[corretoraSelecionada]['total_acoes']"
            bottomValue="-5" bottomText="variação hoje" />
        <app-ativos-card topTitle="Total Proventos (FII's)" [topValue]="dividendos[corretoraSelecionada]['total_fiis']"
            bottomValue="2" bottomText="variação hoje" />
        <app-ativos-card topTitle="Yield on Cost" topFormat="value%"
            [topValue]="dividendos[corretoraSelecionada]['yield_on_cost']" bottomValue="-10" bottomText="variação hoje" />
    </div>

    <div class="grid grid-cols-5 espaco-3">
        <div class="max-h-[calc(100vh-240px)] overflow-auto custom-scroll col-span-3">
            <table mat-table [dataSource]="dsDividendos" class="mt-2" matSort>
                <ng-container matColumnDef="ticker">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Ticker</th>
                    <td mat-cell *matCellDef="let dividendo">{{dividendo.ticker}}</td>
                </ng-container>

                <ng-container matColumnDef="classe">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Classe</th>
                    <td mat-cell *matCellDef="let dividendo">
                        <div [classList]="dividendosColor[dividendo.classe]">
                            {{dividendo.classe}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="setor">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Setor</th>
                    <td mat-cell *matCellDef="let dividendo">{{dividendo.setor}}</td>
                </ng-container>

                <ng-container matColumnDef="preco_unitario">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Preço Unitário</th>
                    <td mat-cell *matCellDef="let dividendo">R$ {{dividendo.preco_unitario | number : '1.2'}}</td>
                </ng-container>

                <ng-container matColumnDef="total">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Total</th>
                    <td mat-cell *matCellDef="let dividendo">R$ {{dividendo.total | number : '1.2'}}</td>
                </ng-container>

                <ng-container matColumnDef="data">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Data</th>
                    <td mat-cell *matCellDef="let dividendo">{{dividendo.data |  date : 'shortDate'}}</td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="colunas"></tr>
                <tr class="striped" mat-row *matRowDef="let dividendos; columns: colunas"></tr>

            </table>
        </div>

        <div class="max-h-[calc(100vh-240px)] overflow-auto p-4 bg-white rounded-3xl grid grid-rows-3 espaco-5 col-span-2 custom-scroll">
            
            <div class="flex flex-col max-h-[calc((100vh-360px)/3)]">
                <div class="font-medium text-gray-500 fonte-1 text-center">Proventos do Ano</div>
                <hr class="my-1" color="#aaa">
                <ngx-charts-bar-vertical class="max-h-[80%]" [results]="dividendos[this.corretoraSelecionada]['historico_ano_corrente']" [xAxis]="true" [scheme]="colorSchemeGreen" />
            </div>

            <div class="grid grid-cols-2 max-h-[calc((100vh-360px)/3)]">
                <div class="flex flex-col">
                    <div class="font-medium text-gray-500 fonte-1 text-center">Total Anual</div>
                    <hr class="my-1" color="#aaa">
                    <ngx-charts-bar-vertical [results]="dividendos[this.corretoraSelecionada]['historico_anual']"  [xAxis]="true" [view]="[200, 200]" [scheme]="colorSchemeYellow"/>
                </div>
                <div class="flex flex-col">
                    <div class="font-medium text-gray-500 fonte-1 text-center">% por Classe</div>
                    <hr class="my-1" color="#aaa">
                    <ngx-charts-pie-chart [results]="dividendos[this.corretoraSelecionada]['porcentagem_ativos']" [doughnut]="true"
                [arcWidth]="0.5" [view]="[200, 200]" [scheme]="colorSchemeDonut"/>
                </div>
            </div>
            
            <div class="flex flex-col max-h-[calc((100vh-360px)/3)]">
                <div class="font-medium text-gray-500 fonte-1 text-center">Total pago por Ativo</div>
                <hr class="my-1" color="#aaa">
                <ngx-charts-bar-horizontal class="max-h-[80%]"  [results]="dividendos[this.corretoraSelecionada]['dividendos']"  [yAxis]="true" [scheme]="colorSchemeCyan" />
            </div>

        </div>
    </div>


</ng-container>
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { KeycloakAuthGuard, KeycloakService } from "keycloak-angular";
import { AuthService } from "./auth.service";

@Injectable({providedIn: 'root'})
export class RouteGuard extends KeycloakAuthGuard{
    
    constructor(router: Router, keycloak: KeycloakService, private auth: AuthService){
        super(router,  keycloak);
    }
    async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if(!this.auth.isLoggedIn())this.router.navigate(['/'])
        return this.auth.isLoggedIn();
    }
    
}
import { Component } from '@angular/core';

@Component({
  selector: 'app-detalhes-ativos',
  standalone: true,
  imports: [],
  templateUrl: './detalhes-ativos.component.html',
  styleUrl: './detalhes-ativos.component.scss'
})
export class DetalhesAtivosComponent {

}

import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { colorSchemeVarious } from '../../../common/chartColorScheme';

export interface CardInvestimentosBens{
  card1: {
    titulo: string;
    historico: any[];
    grafico: any[] | null;
  }
  card2: {
    titulo: string;
    grafico: any[];
  },
  card3: {
    titulo: string;
    grafico: any[];
  }
}

@Component({
  selector: 'app-card-investimentos-bens',
  standalone: true,
  imports: [MatIconModule, NgxChartsModule, CurrencyPipe],
  templateUrl: './card-investimentos-bens.component.html',
  styleUrl: './card-investimentos-bens.component.scss'
})
export class CardInvestimentosBensComponent implements OnInit{

  @Input() data: CardInvestimentosBens;
  totalCard1 = 0;
  colorSchemeVarious = colorSchemeVarious;
  
  
  ngOnInit(): void {
    this.data.card1.historico.forEach( e => this.totalCard1 += e.value);
  }
}


<ng-container *ngIf="!ativos">
    <div class="h-full w-full flex items-center flex-col espaco-4 mt-[10%] cursor-pointer" [routerLink]="['/ativos-brasil', 'importar-ativos']">
        <div class="bg-gray-300 p-10 rounded-full">
            <mat-icon fontIcon="add" class="!w-[75px] !h-[75px] text-[75px] text-gray-400 "/>
        </div>
        <div class="text-gray-400 font-bold">VOCÊ AINDA NÃO POSSUI ATIVOS</div>
    </div>

</ng-container>

<ng-container *ngIf="ativos">
    <div [formGroup]="form">
        <app-select [options]="corretorasOptions" formControlName="corretora" />
    </div>
    
    <div class="grid espaco-3 grid-cards mt-4 w-full mb-2">
        <app-ativos-card topTitle="Total Investido" [topValue]="ativos[corretoraSelecionada]['total_investido']"
            bottomValue="5" bottomText="variação hoje" />
        <app-ativos-card topTitle="Total Investido Ajustado" [topValue]="ativos[corretoraSelecionada]['total_ajustado']"
            bottomValue="-5" bottomText="variação hoje" />
        <app-ativos-card topTitle="Lucro/Prejuízo" [topValue]="ativos[corretoraSelecionada]['lucro_prej']" bottomValue="2"
            bottomText="variação hoje" />
        <app-ativos-card topTitle="Rentabilidade" topFormat="value %"
            [topValue]="ativos[corretoraSelecionada]['rentabilidade']" bottomValue="-10" bottomText="variação hoje" />
    </div>
    
    <div class="max-h-[600px] overflow-auto custom-scroll">
        <table mat-table [dataSource]="dsAtivos" class="mt-2" matSort>
            <ng-container matColumnDef="ticker">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Ticker</th>
                <td mat-cell *matCellDef="let ativo">{{ativo.ticker}}</td>
            </ng-container>
    
            <ng-container matColumnDef="classe">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Classe</th>
                <td mat-cell *matCellDef="let ativo">
                    <div [classList]="ativosColor[ativo.classe]">
                        {{ativo.classe}}
                    </div>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="setor">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Setor</th>
                <td mat-cell *matCellDef="let ativo">{{ativo.setor}}</td>
            </ng-container>
    
            <ng-container matColumnDef="cotacao">
                <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Cotação</th>
                <td mat-cell *matCellDef="let ativo">{{ativo.cotacao | currency : 'BRL'}}</td>
            </ng-container>
    
            <ng-container matColumnDef="preco_medio">
                <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Preço Médio</th>
                <td mat-cell *matCellDef="let ativo">{{ativo.preco_medio | currency : 'BRL'}}</td>
            </ng-container>
    
            <ng-container matColumnDef="lucro_prej">
                <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Lucro/Prej</th>
                <td mat-cell *matCellDef="let ativo">{{ativo.lucro_prej |currency : 'BRL'}}</td>
            </ng-container>
    
            <ng-container matColumnDef="rentabilidade">
                <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Rentabilidade</th>
                <td mat-cell *matCellDef="let ativo">{{ativo.rentabilidade}}%</td>
            </ng-container>
    
            <ng-container matColumnDef="quantidade">
                <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Quantidade</th>
                <td mat-cell *matCellDef="let ativo">{{ativo.quantidade}}</td>
            </ng-container>
    
            <ng-container matColumnDef="custo_ativo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Custo Ativo</th>
                <td mat-cell *matCellDef="let ativo">{{ativo.custo_ativo | currency : 'BRL'}}</td>
            </ng-container>
    
            <ng-container matColumnDef="%">
                <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">%</th>
                <td mat-cell *matCellDef="let ativo">{{ativo['porcentagem']}}</td>
            </ng-container>
    
            <ng-container matColumnDef="valor_atual">
                <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Valor Atual</th>
                <td mat-cell *matCellDef="let ativo">{{ativo['valor_atual'] | currency : 'BRL'}}</td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="colunas"></tr>
            <tr class="striped" mat-row *matRowDef="let ativos; columns: colunas"></tr>
    
        </table>
    </div>
    
    
    <div class="card flex flex-col mt-4 w-full justify-between">
    
        <div class="flex items-center justify-center text-gray-700 pb-2 fonte-3">Alocação do Patrimônio</div>
    
        <hr class="my-1" color="#aaa">
    
        <div class="flex flex-row flex-wrap espaco-3 justify-between items-center min-h-content  p-4">
            <div class="flex flex-col items-center grow lg:border-e">
                <div class="text-primary mt-2 fonte-4 font-semibold">{{ativos[this.corretoraSelecionada]['porcentagem_acoes'].length}}
                    AÇÕES</div>
                <ngx-charts-pie-chart [results]="ativos[this.corretoraSelecionada]['porcentagem_acoes']" [doughnut]="true"
                    [arcWidth]="0.5" [scheme]="colorSchemeVarious" [labels]="true" [view]="[370,300]"/>
            </div>
    
            <div class="flex flex-col items-center grow lg:border-e">
                <div class="text-primary mt-2 fonte-4 font-semibold">
                    {{ativos[this.corretoraSelecionada]['porcentagem_fiis'].length}}FII's</div>
                <ngx-charts-pie-chart [results]="ativos[this.corretoraSelecionada]['porcentagem_fiis']" [doughnut]="true"
                    [arcWidth]="0.5" [scheme]="colorSchemeVarious"  [labels]="true" [view]="[370,300]"/>
            </div>
    
            <div class="flex flex-col items-center grow">
                <div class="text-primary mt-2 fonte-4 font-semibold">{{ativos[this.corretoraSelecionada]['porcentagem_ativos'].length}}
                    CLASSES</div>
                <ngx-charts-pie-chart [results]="ativos[this.corretoraSelecionada]['porcentagem_ativos']" [doughnut]="true"
                    [arcWidth]="0.5" [scheme]="colorSchemeVarious"  [labels]="true" [view]="[370,300]"/>
            </div>
        </div>
    </div>
</ng-container>
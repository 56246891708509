<div mat-dialog-title>Meus Dados</div>
<mat-dialog-content>
    <div class="flex flex-row espaco-5 flex-wrap">
        <div class="flex items-center justify-center flex-wrap">
            <mat-icon class="!w-[150px] !h-[150px] text-[150px] text-gray-300" fontIcon="account_circle"/>
        </div>

        <div class="flex flex-col espaco-3 flex-wrap min-w-[290px]" [formGroup]="form">
            <app-input placeholder="Nome" formControlName="nome"/>
            <app-input placeholder="E-mail" formControlName="email"/>
            <app-input placeholder="Telefone" formControlName="telefone"/>
        </div>

    </div>
    <div class="flex justify-end mt-4">
        <button class="accent rounded-full px-5" (click)="atualiza_usuario()">Salvar</button>
    </div>

</mat-dialog-content>
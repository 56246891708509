import { Corretora } from "./Corretora";

export class Movimentacao{
    id? : number;
    data : string ;
    movimento : string ;
    ticker : string ;
    quantidade : number ;
    preco_unitario : number ;
    corretora : Corretora;
    id_corretora : number;
    valor_operacao: number;
    acao :{};
    criacao : string;
}


// ASSIM VEM DA API
// export class Movimentacao{
//     id? : number;
//     referenceDate : string = '';
//     movementType : string = 'Compra';
//     tickerSymbol : string = '';
//     equitiesQuantity : number = 1;
//     unitPrice : number = 1;
//     corretora : Corretora;
//     acao :{};
//     criacao : string;
// }

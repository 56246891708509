import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getRouteData } from '../../../common/route-utils';
import { Bem } from '../../../models/Bem';
import { LegendPosition, NgxChartsModule } from '@swimlane/ngx-charts';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { AdicionarBemComponent } from '../adicionar-bem/adicionar-bem.component';
import { CategoriaPipe } from '../../../common/categoria-pipe';

@Component({
  selector: 'app-bens',
  standalone: true,
  imports: [NgxChartsModule, MatIconModule, CategoriaPipe],
  templateUrl: './bens.component.html',
  styleUrl: './bens.component.scss',
  providers: [{provide: CategoriaPipe, useClass: CategoriaPipe}]
})
export class BensComponent implements OnInit {
  constructor(private route: ActivatedRoute, private dialog: MatDialog, private router: Router, private toCategoria: CategoriaPipe) { }

  bens: Bem[];
  bensPorNome: any[] = [];
  bensPorCategoria: any[] = [];
  legendPosition: LegendPosition = LegendPosition.Below;
  mostraDescricao: any[] = [];


  ngOnInit(): void {
    this.obtemBens();
  }

  private obtemBens() {
    this.bens = this.route.snapshot.data['value'];
    console.log(this.bens);
    this.bensPorNome = [];
    this.bensPorCategoria = [];
    this.bens.forEach(bem => {
      this.bensPorNome.push({ name: bem.nome, value: bem.valor });
      const categoriaIndex = this.bensPorCategoria.findIndex(categoria => categoria.name == bem.categoria);
      if (categoriaIndex > -1) {
        this.bensPorCategoria[categoriaIndex] = { name: this.toCategoria.transform(bem.categoria), value: this.bensPorCategoria[categoriaIndex].value + bem.valor };
      }
      else this.bensPorCategoria.push({ name: bem.categoria, value: bem.valor });
      this.mostraDescricao.push(false);
    });
    
  }

  toggleDescricao(index) {
    this.mostraDescricao[index] = !this.mostraDescricao[index]
  }

  openAdicionarBem(bem: any = null) {
    this.dialog.open(AdicionarBemComponent, { data: bem }).afterClosed().subscribe({
      next: r => {console.log(r); this.router.navigate(['/bens'], {skipLocationChange: true}).then(()=>this.router.navigate(['/bens', 'meus-bens']))}
    })
  }
}

import { AfterViewInit, Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-textarea',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './textarea.component.html',
  styleUrl: './textarea.component.scss',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TextareaComponent), multi: true }
  ]
})
export class TextareaComponent implements OnInit, ControlValueAccessor, AfterViewInit {
  constructor(private builder: FormBuilder) { }

  form: FormGroup;
  placeholderTop = false;
  @Input() placeholder = '';
  @Input() rows = 6;
  @Input() maxlength = 1000;
  @Input() class = '';


  ngOnInit(): void {
    this.form = this.builder.group({
      'input': []
    });
  }

  ngAfterViewInit(): void {
    this.form.valueChanges.subscribe(
      c => { this.onChange(c['input']) }
    )
  }
  onChange(val: string) { }

  writeValue(val: string): void {
    if (val) {
      this.form.get('input')?.setValue(val);
      this.placeholderTop = true;
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {

  }
  setDisabledState?(isDisabled: boolean): void {

  }





}

<div class="side-panel" [class.open]="menuOpen" [class.closed]="!menuOpen">
    <!-- Menu items -->
    <div class="menu" [class.menu-open]="menuOpen" [class.menu-closed]="!menuOpen">
        <img class="h-[44px] w-fit" [src]="'assets/' + (menuOpen ? 'logo_full' : 'logo_min') + '.png'" />



        <!-- ATIVOS BRASIL -->
        <mat-accordion multi>
            <a routerLink="/dashboard" routerLinkActive="active-link"
                class="flex items-center espaco-3 text-[13px] py-[3px] ps-[4px] pe-[40px] w-min">
                <mat-icon fontIcon="space_dashboard" />{{menuOpen ? 'Dashboard ' : ''}}</a>

            <mat-expansion-panel [hideToggle]="!menuOpen" class="!bg-transparent !shadow-none w-[80px]"
                [disabled]="!menuOpen">
                <mat-expansion-panel-header [class.menu-contracted]="!menuOpen">
                    <mat-panel-title [class.active-link]="router.url.startsWith('/ativos-brasil')">
                        <mat-icon fontIcon="paid" /> {{menuOpen ? 'Ativos Brasil' : ''}}
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="panel-content" *ngIf="menuOpen">
                    <a routerLink="/ativos-brasil/importar-ativos" routerLinkActive="active-link"><mat-icon
                            fontIcon="upload" />Importar Ativos</a>
                    <a routerLink="/ativos-brasil/movimentacoes" routerLinkActive="active-link"><mat-icon
                            fontIcon="attach_money" />Movimentações</a>
                    <a routerLink="/ativos-brasil/ativos" routerLinkActive="active-link"><mat-icon
                            fontIcon="attach_money" />Ativos</a>
                    <a routerLink="/ativos-brasil/dividendos" routerLinkActive="active-link"><mat-icon
                            fontIcon="attach_money" />Dividendos</a>
                    <a routerLink="/ativos-brasil/rebalanceamento" routerLinkActive="active-link"><mat-icon
                            fontIcon="attach_money" />Rebalanceamento</a>
                    <a routerLink="/ativos-brasil/detalhes-ativos" routerLinkActive="active-link"><mat-icon
                            fontIcon="attach_money" />Detalhes dos Ativos</a>

                </div>
            </mat-expansion-panel>

            <!-- Renda Fixa -->
            <a routerLink="/renda-fixa" routerLinkActive="active-link"
            class="flex items-center espaco-3 text-[13px] py-[3px] ps-[4px] pe-[40px] w-min text-nowrap">
            <mat-icon fontIcon="savings" />{{menuOpen ? 'Renda Fixa ' : ''}}</a>

            <!-- Bens -->
            <mat-expansion-panel [hideToggle]="!menuOpen" class="!bg-transparent !shadow-none w-[80px]"
                [disabled]="!menuOpen">
                <mat-expansion-panel-header [class.menu-contracted]="!menuOpen">
                    <mat-panel-title [class.active-link]="router.url.startsWith('/bens')">
                        <mat-icon fontIcon="directions_car" /> {{menuOpen ? 'Bens' : ''}}
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="panel-content" *ngIf="menuOpen">
                    <a routerLink="/bens/meus-bens" routerLinkActive="active-link"><mat-icon
                            fontIcon="attach_money" />Meus Bens</a>
                    <a routerLink="/bens/adicionar-bem" routerLinkActive="active-link"><mat-icon
                            fontIcon="attach_money" />Adicionar Bem</a>
                </div>
            </mat-expansion-panel>

            <!-- Criptomoedas -->
            <mat-expansion-panel [hideToggle]="!menuOpen" class="!bg-transparent !shadow-none w-[80px]"
                [disabled]="!menuOpen">
                <mat-expansion-panel-header [class.menu-contracted]="!menuOpen">
                    <mat-panel-title [class.active-link]="router.url.startsWith('/criptomoedas')">
                        <mat-icon fontIcon="currency_bitcoin" /> {{menuOpen ? 'Criptomoedas' : ''}}
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="panel-content" *ngIf="menuOpen">
                    <a routerLink="/ativos-brasil/importar-ativos" routerLinkActive="active-link"><mat-icon
                            fontIcon="upload" />Importar Ativos</a>
                    <a routerLink="/ativos-brasil/ativos" routerLinkActive="active-link"><mat-icon
                            fontIcon="attach_money" />Ativos</a>
                    <a routerLink="/ativos-brasil/dividendos" routerLinkActive="active-link"><mat-icon
                            fontIcon="attach_money" />Dividendos</a>
                    <a routerLink="/ativos-brasil/rebalanceamento" routerLinkActive="active-link"><mat-icon
                            fontIcon="attach_money" />Rebalanceamento</a>
                    <a routerLink="/ativos-brasil/detalhes-ativos" routerLinkActive="active-link"><mat-icon
                            fontIcon="attach_money" />Detalhes dos Ativos</a>
                </div>
            </mat-expansion-panel>

            <!-- Ativos Exterior -->
            <mat-expansion-panel [hideToggle]="!menuOpen" class="!bg-transparent !shadow-none w-[80px]"
                [disabled]="!menuOpen">
                <mat-expansion-panel-header [class.menu-contracted]="!menuOpen">
                    <mat-panel-title [class.active-link]="router.url.startsWith('/ativos-exterior')">
                        <mat-icon fontIcon="travel_explore" /> {{menuOpen ? 'Ativos Exterior' : ''}}
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="panel-content" *ngIf="menuOpen">
                    <a routerLink="/ativos-brasil/importar-ativos" routerLinkActive="active-link"><mat-icon
                            fontIcon="upload" />Importar Ativos</a>
                    <a routerLink="/ativos-brasil/ativos" routerLinkActive="active-link"><mat-icon
                            fontIcon="attach_money" />Ativos</a>
                    <a routerLink="/ativos-brasil/dividendos" routerLinkActive="active-link"><mat-icon
                            fontIcon="attach_money" />Dividendos</a>
                    <a routerLink="/ativos-brasil/rebalanceamento" routerLinkActive="active-link"><mat-icon
                            fontIcon="attach_money" />Rebalanceamento</a>
                    <a routerLink="/ativos-brasil/detalhes-ativos" routerLinkActive="active-link"><mat-icon
                            fontIcon="attach_money" />Detalhes dos Ativos</a>
                </div>
            </mat-expansion-panel>

            <!-- Ferramentas -->
            <mat-expansion-panel [hideToggle]="!menuOpen" class="!bg-transparent !shadow-none w-[80px]"
                [disabled]="!menuOpen">
                <mat-expansion-panel-header [class.menu-contracted]="!menuOpen">
                    <mat-panel-title [class.active-link]="router.url.startsWith('/ferramentas')">
                        <mat-icon fontIcon="build" /> {{menuOpen ? 'Ferramentas' : ''}}
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="panel-content" *ngIf="menuOpen">
                    <a routerLink="/ativos-brasil/importar-ativos" routerLinkActive="active-link"><mat-icon
                            fontIcon="upload" />Importar Ativos</a>
                    <a routerLink="/ativos-brasil/ativos" routerLinkActive="active-link"><mat-icon
                            fontIcon="attach_money" />Ativos</a>
                    <a routerLink="/ativos-brasil/dividendos" routerLinkActive="active-link"><mat-icon
                            fontIcon="attach_money" />Dividendos</a>
                    <a routerLink="/ativos-brasil/rebalanceamento" routerLinkActive="active-link"><mat-icon
                            fontIcon="attach_money" />Rebalanceamento</a>
                    <a routerLink="/ativos-brasil/detalhes-ativos" routerLinkActive="active-link"><mat-icon
                            fontIcon="attach_money" />Detalhes dos Ativos</a>
                </div>
            </mat-expansion-panel>


            <!-- Admin -->
            <mat-expansion-panel [hideToggle]="!menuOpen" class="!bg-transparent !shadow-none w-[80px]"
                [disabled]="!menuOpen">
                <mat-expansion-panel-header [class.menu-contracted]="!menuOpen">
                    <mat-panel-title [class.active-link]="router.url.startsWith('/admin')">
                        <mat-icon fontIcon="admin_panel_settings" /> {{menuOpen ? 'Admin' : ''}}
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="panel-content" *ngIf="menuOpen">
                    <a routerLink="/admin/eventos-admin" routerLinkActive="active-link"><mat-icon
                            fontIcon="event" />Eventos Globais</a>
                    <a routerLink="/admin/usuarios-admin" routerLinkActive="active-link"><mat-icon
                            fontIcon="group" />Usuários</a>
                    <a routerLink="/admin/mensagens-admin" routerLinkActive="active-link"><mat-icon
                            fontIcon="chat_bubble" />Mensagens</a>
                    <a routerLink="/admin/assinaturas-admin" routerLinkActive="active-link"><mat-icon
                            fontIcon="subscriptions" />Assinaturas</a>
                    <a routerLink="/admin/sugestoes-admin" routerLinkActive="active-link"><mat-icon
                            fontIcon="maps_ugc" />Sugestões</a>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>

    <!-- Hamb menu -->
    <div class="flex items-center justify-center" (click)="openSideMenu()" [class.ps-20]="menuOpen">
        <mat-icon class="text-white cursor-pointer" fontIcon="menu" />
    </div>
</div>
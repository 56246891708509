<div mat-dialog-title>Adicionar Título</div>
<mat-dialog-content>
    <div [formGroup]="form">
        <app-input-alt formControlName="nome" label="Nome" classInput="border border-red-500"/>
        <app-input-alt formControlName="valor" label="Valor"/>

        <select class=" text-[14px] col-span-2 py-2" formControlName="id_corretora" formControlName="id_corretora">
            @for (corretora of app.corretoras; track $index) {
            <option [value]="corretora.id">{{corretora.nome}}</option>
            }
        </select>

    </div>
</mat-dialog-content>
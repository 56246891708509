<mat-vertical-stepper linear="true">
    <mat-step>
        <ng-template matStepLabel>Como deseja importar?</ng-template>
        <div class="card max-w-[500px] flex flex-col">
            <div class="font-semibold mb-4">Selecione como deseja importar sua carteira</div>

            <div class="flex cursor-pointer w-fit hover:opacity-75">
                <div class="w-[60px] h-[60px] bg-white rounded-full flex items-center justify-center z-10 relative"><img
                        src="assets/icon_b3.png" width="40px"></div>
                <div class="-ml-10 ps-14 pe-4 flex items-center primary rounded-3xl text-white my-2 p-2 z-1 relative">
                    Importar pela B3 (Usuários Premium)</div>
            </div>

            <button class="flex cursor-pointer w-fit hover:opacity-75 custom" matStepperNext>
                <div class="w-[60px] h-[60px] bg-white rounded-full flex items-center justify-center z-10 relative"><img
                        src="assets/icon_excel.png" width="40px"></div>
                <div class="-ml-10 ps-14 pe-4 flex items-center primary rounded-3xl text-white my-2 p-2 z-1 relative">
                    Importação Manual</div>
            </button>
        </div>
    </mat-step>

    <mat-step >
        <ng-template matStepLabel>Instruções de importação</ng-template>
        <div class="card !bg-gray-200 mt-4">Instruções ensinando como logar</div>
        <div class="text-justify mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis ut nibh sit amet
            purus posuere bibendum.
            Fusce interdum purus non libero lacinia tincidunt. Cras ac imperdiet nisl. Proin dictum massa mauris,
            finibus rutrum purus euismod quis. Donec vulputate laoreet quam ac accumsan. Nulla nec condimentum
            lacus. Nulla quam velit, vulputate vel vehicula mattis, consectetur non erat. Nulla a volutpat metus,
            quis lacinia ipsum. In condimentum elit non quam tempor, id pretium sapien sollicitudin. Mauris quis
            arcu sit amet massa dapibus pharetra. Maecenas vitae elementum erat, id accumsan augue. Pellentesque
            lobortis sapien sed mauris maximus, sit amet iaculis mi malesuada. Etiam pellentesque tortor eu tortor
            lobortis, quis commodo mi laoreet. Quisque rhoncus, dui a rutrum sodales, massa risus rutrum elit, vitae
            hendrerit tortor ipsum ac erat. Sed ac nisi nec dui aliquet porta a vitae velit. Orci varius natoque
            penatibus et magnis dis parturient montes, nascetur ridiculus mus.</div>
        <div class="max-w-[500px] mt-2">
            <img src="assets/instrucoes.jpeg" />
        </div>

        <div class="flex justify-end"><button class="accent" matStepperNext>Avançar</button></div>
    </mat-step>

    <mat-step [stepControl]="step3Form">
        <ng-template matStepLabel>Upload de Extratos</ng-template>
        <div class="card flex flex-col">
            <div class="font-semibold mb-4">Faça a importação dos Extratos</div>
            <div class="grid grid-cols-2 espaco-5">

                <div class="border-r ">
                    <button class="accent flex espaco-1 max-w-fit" (click)="movimentacao.click()">
                        <mat-icon fontIcon="upload" />Extrato de Movimentação
                    </button>

                    <div class="px-4 mt-3 flex flex-col espaco-2 h-[116px] fonte-1">
                        <div class="flex espaco-1 items-center"><mat-icon
                                [fontIcon]="transacoes.length > 0 ? 'check' : 'close'"
                                [class]="transacoes.length > 0 ? 'text-green ': 'text-red'" />Proventos
                        </div>
                        <div class="flex espaco-1 items-center"><mat-icon
                                [fontIcon]="transacoes.length > 0 ? 'check' : 'close'"
                                [class]="transacoes.length > 0 ? 'text-green ': 'text-red'" />Renda
                            Fixa</div>
                        <div class="flex espaco-1 items-center"><mat-icon
                                [fontIcon]="transacoes.length > 0 ? 'check' : 'close'"
                                [class]="transacoes.length > 0 ? 'text-green ': 'text-red'" />Eventos
                            de Grupamento e
                            Desdobramento</div>
                    </div>

                    <!-- NOMES DOS ARQUIVOS DE MOVIMENTAÇÃO -->
                    @if(transacoes.length > 0){
                    <div class="grid grid-cols-6 w-fit gap-2">
                        @for (item of arquivosCarregados.movimentacao; track $index) {
                        <div class="ps-3 text-gray-500 fonte-1 flex gap-1 fonte-1 col-span-5">
                            {{item.arquivo.name}}
                        </div>
                        @if(item.status == 'processando'){<mat-icon fontIcon="cached" [inline]="true"
                            class="text-orange-500" />}
                        @if(item.status == 'sucesso'){<mat-icon fontIcon="check_circle" [inline]="true"
                            class="text-green" />}
                        @if(item.status == 'falha'){<mat-icon fontIcon="error_outline" [inline]="true"
                            class="text-red" />}
                        }</div>}
                </div>

                <div class="flex flex-col">
                    <button class="accent flex espaco-1 max-w-fit" (click)="negociacao.click()">
                        <mat-icon fontIcon="upload" />Extrato de Negociação
                    </button>

                    <div class="px-4 mt-3  flex flex-col espaco-2 h-[116px] fonte-1">
                        <div class="flex espaco-1 items-center"><mat-icon
                                [fontIcon]="transacoes.length > 0 ? 'check' : 'close'"
                                [class]="transacoes.length > 0 ? 'text-green ': 'text-red'" />Ações
                        </div>
                        <div class="flex espaco-1 items-center"><mat-icon
                                [fontIcon]="transacoes.length > 0 ? 'check' : 'close'"
                                [class]="transacoes.length > 0 ? 'text-green ': 'text-red'" />Fundos
                            Imobiliários</div>

                    </div>

                    <!-- NOMES DOS ARQUIVOS DE NEGOCIAÇÃO -->
                    @if(transacoes.length > 0){
                    <div class="grid grid-cols-6 w-fit gap-2">
                        @for (item of arquivosCarregados.negociacao; track $index) {
                        <div class="ps-3 text-gray-500 fonte-1 flex gap-1 fonte-1 col-span-5">
                            {{item.arquivo.name}}
                        </div>
                        @if(item.status == 'processando'){<mat-icon fontIcon="cached" [inline]="true"
                            class="text-orange-500" />}
                        @if(item.status == 'sucesso'){<mat-icon fontIcon="check_circle" [inline]="true"
                            class="text-green" />}
                        @if(item.status == 'falha'){<mat-icon fontIcon="error_outline" [inline]="true"
                            class="text-red" />}
                        }
                    </div>
                    }
                </div>
            </div>
        </div>

        <input type="file" #movimentacao class="hidden" (change)="extratoCarregado($event, 'movimentacao')"
            multiple="true">
        <input type="file" #negociacao class="hidden" (change)="extratoCarregado($event, 'negociacao')" multiple="true">
        <div class="flex justify-end"><button class="accent" matStepperNext [disabled]="step3Form.invalid">Avançar</button></div>
    </mat-step>

    <mat-step >
        <div class="grid grid-cols-2 mt-4">
            <div class="fonte-2 font-semibold">Movimentação</div>
            <div class="fonte-2 font-semibold">Negociação</div>
            
            @for (tipo of ['movimentacao', 'negociacao']; track $index) {
                <div class="grid grid-cols-4 fonte-1 mt-2 w-fit">
                    @for (item of arquivosCarregados[tipo]; track $index) {
                        @if(item.status == 'sucesso'){
                    <div class="col-span-3 max-w-[250px] truncate">{{item.arquivo.name}}</div>
                    <div>{{item.transacoesImportadas}} registros</div>
                        }
                    }
                </div>
            }

        </div>
        <div class="flex justify-end mt-4">
            <button (click)="importarTransacoes()" class="mt-4 flex items-center espaco-3"
                *ngIf="transacoes.length > 0">
                <mat-icon fontIcon="check" />Finalizar Importação
            </button>
        </div>
        <ng-template matStepLabel>Confirmar Importação</ng-template>
    </mat-step>
</mat-vertical-stepper>
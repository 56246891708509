import { AbstractControl, ValidatorFn } from "@angular/forms"

export const ValidaComplexidade  : ValidatorFn = (control: AbstractControl) => {
    let senha = control.value;
    const hasUpperCase = /[A-Z]+/.test(senha);
    const hasLowerCase = /[a-z]+/.test(senha);
    const hasNumeric = /[0-9]+/.test(senha);
    const passworValid = hasUpperCase && hasLowerCase && hasNumeric;
    return passworValid ? null : {senhaInvalida: true}
}

export const ValidaSenhasIguais: ValidatorFn = (control: AbstractControl) => {
    let senha = control.get('senha')?.value;
    let confirmaSenha = control.get('confirmaSenha')?.value;
    return senha === confirmaSenha ? null : {senhaNaoIgual: true}

}
<table mat-table [dataSource]="usuariosDs">

    <mat-header-row *matHeaderRowDef="colunas"></mat-header-row>
    <mat-row *matRowDef="let usuario; columns: colunas"></mat-row>
    
    <ng-container matColumnDef="nome">
        <mat-header-cell *matHeaderCellDef>Nome</mat-header-cell>
        <mat-cell *matCellDef="let usuario">{{usuario.nome}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef>E-mail</mat-header-cell>
        <mat-cell *matCellDef="let usuario">{{usuario.email}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="criacao">
        <mat-header-cell *matHeaderCellDef>Registro</mat-header-cell>
        <mat-cell *matCellDef="let usuario">{{usuario.criacao | date : 'short'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="ativo">
        <mat-header-cell *matHeaderCellDef>Ativo</mat-header-cell>
        <mat-cell *matCellDef="let usuario">
            <mat-icon class="text-red-500 text-[34px] !w-[38px] cursor-pointer" fontIcon="toggle_off" *ngIf="!usuario.ativo" (click)="toggleUsuarioAtivo(false, usuario.id)"/>
            <mat-icon class="text-green-500 text-[34px] !w-[38px] cursor-pointer" fontIcon="toggle_on" *ngIf="usuario.ativo" (click)="toggleUsuarioAtivo(true, usuario.id)"/>
        </mat-cell>
    </ng-container>

 
</table>
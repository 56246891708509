<div class="flex espaco-3 mb-4">
    <button class="accent rounded-full px-5" (click)="adicionarLancamento()">Adicionar Movimentação</button>
    <!-- <button class="accent rounded-full px-5" (click)="filtrarCorretora()">Filtrar Corretora</button> -->
    <button class="accent rounded-full px-5" (click)="editarMovimentacoes()"
        [disabled]="idsSelecionados.size == 0">Editar Movimentação</button>
    <button class="warn px-5" (click)="excluirMovimentacoes()" [disabled]="idsSelecionados.size == 0">
        Excluir Movimentações
    </button>
</div>

<mat-expansion-panel>
    <mat-expansion-panel-header>
        <mat-panel-title>Filtros</mat-panel-title>
    </mat-expansion-panel-header>
    <div class="flex espaco-2 w-full justify-between flex-wrap py-2" [formGroup]="filtros">
        <app-input-alt placeholder="Data" type="month" label="Data" formControlName="data"/>
        <app-select [rounded]="false" [options]="tiposMovimentacao" [multiple]="true" label="Movimentos" formControlName="movimentacao"/>
        <app-input-alt placeholder="Ativo" label="Ativo" formControlName="ativo"/>
        <app-select [rounded]="false" [options]="app.corretoras" [multiple]="true" classSelect="max-w-[260px]" label="Corretoras" formControlName="corretora"/>
        <app-input-alt placeholder="Qtd" label="Qtd" class="input-pequeno" formControlName="quantidade"/>
        <app-input-alt placeholder="Preço" label="Preço" class="input-pequeno" formControlName="preco"/>
        <app-input-alt placeholder="Total" label="Total" class="input-pequeno" formControlName="total"/>

        <!-- <select multiple formControlName="movimentacao">
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
        </select> -->
    </div>
    <div class="flex espaco-2 mt-2">
        <button class=" accent" (click)="filtrar()">Filtrar</button>
        <button class=" accent" (click)="limparFiltros()">Limpar</button>
    </div>
</mat-expansion-panel>


<div *ngIf="movimentacoes.length > 0" class="overflow-auto custom-scroll w-full mt-2">
    <table mat-table [dataSource]="dsMovimentacoes">

        <!-- SELECAO -->
        <ng-container matColumnDef="selecao">
            <th mat-header-cell *matHeaderCellDef>
                <div class="flex items-center">
                    <mat-icon fontIcon="check" class="cursor-pointer text-green fonte-3 font-bold "
                        (click)="toggleSelecionarTodas()"></mat-icon>
                </div>
            </th>
            <td mat-cell *matCellDef="let movimentacao; let i = index" class="">
                <!-- <div class="flex items-center justify-center "> -->
                <!-- <mat-icon fontIcon="check_box" class="text-green-600 text-[18px] font-bold" *ngIf="idsSelecionados.has(i)" /> -->
                <!-- </div> -->
            </td>
        </ng-container>

        <!-- DATA -->
        <ng-container matColumnDef="data">
            <th mat-header-cell *matHeaderCellDef>
                <div class="flex flex-col espaco-1 justify-center w-full">
                    Data
                </div>
            </th>
            <td mat-cell *matCellDef="let movimentacao">
                {{movimentacao.data | date : 'shortDate'}}
            </td>
        </ng-container>

        <!-- MOVIMENTO -->
        <ng-container matColumnDef="movimentacao">
            <th mat-header-cell *matHeaderCellDef>
                <div class="flex flex-col espaco-1 justify-center  w-full">
                    Movimentação
                </div>
            </th>
            <td mat-cell *matCellDef="let movimentacao">
                <div class="max-w-[140px] truncate">{{movimentacao.movimento }}</div>
            </td>
        </ng-container>

        <!-- ATIVO -->
        <ng-container matColumnDef="ativo">
            <th mat-header-cell *matHeaderCellDef>
                <div class="flex flex-col espaco-1 justify-center w-full">
                    <!-- <app-input-alt class="w-[100px]" formControlName="ticker"/> -->
                    Ativo
                </div>
            </th>
            <td mat-cell *matCellDef="let movimentacao">
                {{movimentacao.ticker}}
            </td>
        </ng-container>

        <!-- CORRETORA -->
        <ng-container matColumnDef="corretora">
            <th mat-header-cell *matHeaderCellDef>Corretora</th>
            <td mat-cell *matCellDef="let movimentacao" class="max-w-[240px] truncate">
                {{movimentacao.corretora?.nome}}
            </td>
        </ng-container>

        <!-- QUANTIDADE -->
        <ng-container matColumnDef="quantidade">
            <th mat-header-cell *matHeaderCellDef>Qtd</th>
            <td mat-cell *matCellDef="let movimentacao">
                {{movimentacao.quantidade}}
            </td>
        </ng-container>

        <!-- PRECO -->
        <ng-container matColumnDef="preco">
            <th mat-header-cell *matHeaderCellDef>Preço</th>
            <td mat-cell *matCellDef="let movimentacao">
                R$ {{movimentacao.preco_unitario | number : '1.2'}}
            </td>
        </ng-container>

        <!-- TOTAL -->
        <ng-container matColumnDef="total">
            <th mat-header-cell *matHeaderCellDef>Total</th>
            <td mat-cell *matCellDef="let movimentacao">
                R$ {{movimentacao.valor_operacao | number : '1.2'}}
            </td>
        </ng-container>



        <tr mat-header-row *matHeaderRowDef="colunas"></tr>
        <tr mat-row *matRowDef="let movimentacao; let i = index ;columns: colunas"
            (click)="selecionaLinha(movimentacao.id, i, $event)" [class.linha-selecionada]="idsSelecionados.has(movimentacao.id)"
            class="cursor-pointer select-none">
        </tr>

    </table>
</div>
import { Injectable } from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import { AppService } from "./app.service";
import { jwtDecode } from "jwt-decode";

@Injectable({providedIn: 'root'})
export class AuthService{
    constructor(private keycloak: KeycloakService, private app: AppService){}

    public async login() {
        if(!this.keycloak.isLoggedIn())
        await this.keycloak.login();
    }

    public async logout(){
        if(this.keycloak.isLoggedIn())
            await this.keycloak.logout();
    }

    public isLoggedIn(){
        return this.keycloak.isLoggedIn();
    }

    public async setUser(){
        console.log(this.keycloak.isLoggedIn())
        const token = await this.keycloak.getToken();
        if(token){
            console.log(token)
            let data = jwtDecode(token);
            console.log(data)
        }
    }


}
import { Component } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RendaFixaDialogComponent } from '../renda-fixa-dialog/renda-fixa-dialog.component';

@Component({
  selector: 'app-renda-fixa-card',
  standalone: true,
  imports: [MatIconModule, MatDialogModule],
  templateUrl: './renda-fixa-card.component.html',
  styleUrl: './renda-fixa-card.component.scss'
})
export class RendaFixaCardComponent {

  constructor(private dialog: MatDialog){}
  mouseOverCard = false;
  
  abrirDialog(){
    this.dialog.open(RendaFixaDialogComponent).afterClosed().subscribe(
      r => {}
    )
  }
}

import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SideMenuComponent } from '../side-menu/side-menu.component';
import { ProfileMenuComponent } from '../profile-menu/profile-menu.component';
import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';

@Component({
  selector: 'app-area-logada',
  standalone: true,
  imports: [RouterModule, SideMenuComponent, ProfileMenuComponent, BreadcrumbComponent],
  templateUrl: './area-logada.component.html',
  styleUrl: './area-logada.component.scss'
})
export class AreaLogadaComponent {

}

<div class="grid espaco-3 grid-cards mt-4 w-full mb-2">
    <app-ativos-card topTitle="Total Investido No Brasil" [topValue]="dashboard['ativos']['totais']['total_investido']" bottomValue="5"
        bottomText="variação hoje" />
    <app-ativos-card topTitle="Valor em Ações BR" [topValue]="dashboard['ativos']['totais']['total_acoes']" bottomValue="-5" bottomText="variação hoje" />
    <app-ativos-card topTitle="Valor em FIIs BR" [topValue]="dashboard['ativos']['totais']['total_fiis']" bottomValue="2" bottomText="variação hoje" />
    <app-ativos-card topTitle="Renda Fixa" [topValue]="2000" bottomValue="2" bottomText="variação hoje" />

    <app-ativos-card topTitle="Total Investino no Exterior" [topValue]="10532" bottomValue="2"
        bottomText="variação hoje" />
    <app-ativos-card topTitle="Criptomoedas" [topValue]="9031" bottomValue="2" bottomText="variação hoje" />
    <app-ativos-card topTitle="Bens" [topValue]="9031" bottomValue="2" bottomText="variação hoje" />
    <app-ativos-card topTitle="Patrimonio Total" [topValue]="30152" bottomValue="2" bottomText="variação hoje" />
</div>


<div class="max-h-[calc(100vh-420px)] overflow-auto custom-scroll flex flex-col espaco-5">
   <app-card-investimentos-bens [data]="cardInvestimentos"/>

    <div class="card">
        <div class="h-[300px]">
            <div class="pb-2 w-full flex justify-center fonte-3 border-b border-gray-300">Dividendos Brasil</div>
            <ngx-charts-bar-vertical [results]="dashboard['dividendos']['totais']['ano_corrente']"
                [xAxis]="true" [scheme]="colorSchemeGreen"/>
        </div>
    </div>
    <app-card-investimentos-bens [data]="cardBens"/>

</div>
<mat-dialog-content class="primary adicionar-lancamento-frame max-w-[400px]">
    <div class=" flex justify-center items-center flex-col espaco-4 py-2" [formGroup]="form">
        <img src="assets/logo_full.png" width="160">
        <div class="fonte-1 text-semibold text-white mt-2">
            @if(!movimentacoes){ Adicionar Movimentação}
            @else if(movimentacoes && movimentacoes.length == 1){Editar Movimentação}
            @else if(movimentacoes && movimentacoes.length > 1){Editar Múltiplas Movimentações}
        </div>
        @if(movimentacoes && movimentacoes.length > 1){
        <div class="bg-gray-100 w-min-content  p-2 fonte-1 text-center">
            Atenção, os valores alterados serão atualizados para todas as {{movimentacoes && movimentacoes.length}}
            movimentações selecionadas.
        </div>
        }

        <div class="grid grid-cols-2 espaco-4">
            <app-input-alt type="date" classInput="text-[14px]" formControlName="data" label="Data" classLabel="text-white"/>
            <!-- <app-select [options]="tipoMovimentacao" formControlName="movimento"
                class="max-w-[170px] text-[14px]" [rounded]="false" /> -->

                <div class="flex flex-col h-full">
                    <label class="text-white text-[14px]" for="movimento">Movimento</label>
                    <select id="movimento" class=" text-[14px] py-[9px]" formControlName="movimento">
                        @for (movimento of tiposMovimentacao; track $index) {
                        <option [value]="movimento.id">{{movimento.nome}}</option>
                        }
                    </select>
                </div>

            <app-autocomplete [options]="app.tickers" class="w-full text-[14px]" placeholder=""
                formControlName="ticker" label="Ticker" classLabel="text-white"/>
            <app-input-alt placeholder="" classInput="w-full text-[14px]" formControlName="quantidade" label="Quantidade" classLabel="text-white"/>

            <app-input-alt placeholder="" classInput="w-full text-[14px]" formControlName="preco_unitario"  label="Preço Unitário" classLabel="text-white"/>
            <app-input-alt placeholder="" classInput="w-full text-[14px]" formControlName="valor_operacao" label="Valor da Operação" classLabel="text-white" />

            <div class="flex flex-col h-full col-span-2">
                <label class="text-white text-[14px]" for="corretora">Corretora</label>
            <select id="corretora" class=" text-[14px] col-span-2 py-2" formControlName="id_corretora" formControlName="id_corretora">
                @for (corretora of app.corretoras; track $index) {
                <option [value]="corretora.id">{{corretora.nome}}</option>
                }
            </select>
            </div>

        </div>
        <div class="flex espaco-3 mt-2" *ngIf="!this.movimentacoes">
            <input type="checkbox" id="outras" class="w-[16px] h-[16px]" formControlName="adicionar_outras"/>
            <label for="outras" class="text-white fonte-1">Adicionar mais movimentações</label>
        </div>
        <button class="accent rounded-full w-[130px] mt-2" (click)="adicionarMovimentacao()">Salvar</button>
    </div>

</mat-dialog-content>
import { Component, ViewChild } from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatAccordion, MatExpansionModule, MatExpansionPanel} from '@angular/material/expansion';
import { provideNativeDateAdapter } from '@angular/material/core';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-side-menu',
  standalone: true,
  imports: [MatIconModule, MatExpansionModule, RouterModule, CommonModule],
  templateUrl: './side-menu.component.html',
  styleUrl: './side-menu.component.scss',
  providers: [provideNativeDateAdapter()],
  viewProviders: [MatExpansionPanel]
})
export class SideMenuComponent {

  constructor(public router: Router){}
  @ViewChild(MatAccordion) accordion: MatAccordion;
  menuOpen = true;
  openSideMenu(){
    // setTimeout(() => {

    // }, 500)
    this.accordion.closeAll();
    this.menuOpen = !this.menuOpen;

  }
}

<div class="title">Meus Bens</div>


<div class="flex justify-end w-full">
        <button class="flex items-center espaco-3" (click)="openAdicionarBem()">
            <mat-icon fontIcon="add"/>Adicionar Bem
        </button>
    </div>
<!-- Tabela de bens -->
<div *ngIf="bens.length > 0"> 
    <div class="grid grid-cols-5 grid-header">
        <div class="col-span-2">Nome</div>
        <div class="col-span-2">Categoria</div>
        <div>Valor</div>
    </div>

    @for (bem of bens; track $index) {
    <div class="grid grid-cols-5 grid-pill text-gray-700 items-center">
        <div class="col-span-2 cursor-pointer" (click)="openAdicionarBem(bem)">{{bem.nome}}</div>
        <div class="col-span-2">{{bem.categoria | toCategoria}}</div>
        <div class="flex espaco-3">{{bem.valor}}<mat-icon class="cursor-pointer" fontIcon="expand_more"
                (click)="toggleDescricao($index)"/>
        </div>
        <div class="col-span-full" *ngIf="mostraDescricao[$index]">
            {{bem.descricao}}
        </div>
    </div>
    }
</div>

<!-- Gráficos de bens -->

<div class="flex flex-row flex-wrap espaco-3 mt-10" *ngIf="bens.length > 0">
    <div class="flex flex-col espaco-3 items-center">
        <div class="text-primary">BENS POR NOME</div>
        <ngx-charts-pie-chart [view]="[600, 400]" [results]="bensPorNome" [labels]="true" />
    </div>

    <div class="flex flex-col espaco-3 items-center">
        <div class="text-primary">BENS POR CATEGORIA</div>
        <ngx-charts-pie-chart [view]="[600, 400]" [results]="bensPorCategoria" [labels]="true" />
    </div>
</div>
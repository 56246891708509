import { Component, OnInit } from '@angular/core';
import { AtivosCardComponent } from '../ativos/ativos-card/ativos-card.component';
import { InputAltComponent } from '../../../common/input-alt/input-alt.component';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { getRouteData } from '../../../common/route-utils';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-rebalanceamento',
  standalone: true,
  imports: [AtivosCardComponent, InputAltComponent, ReactiveFormsModule, CurrencyPipe],
  templateUrl: './rebalanceamento.component.html',
  styleUrl: './rebalanceamento.component.scss'
})
export class RebalanceamentoComponent implements OnInit{

  constructor(private builder:FormBuilder, private route: ActivatedRoute){}
  form:FormGroup;
  rebalanceamento;

  ngOnInit(){
    this.rebalanceamento = getRouteData(this.route);
    console.log(this.rebalanceamento.corretoras['CLEAR CORRETORA - GRUPO XP'].ativos)
    this.form = this.builder.group({
      aporte: [0],
      aporteIdeal : [{value: 0, disabled: true}]
    })
  }
}

<button (click)="teste()">TESTE</button>
<div class="grid grid-cols-2 min-h-full h-screen">
    <div class="primary flex items-center justify-center">
        <img src="assets/logo_full.png" width="400px">
    </div>
    <div class="bg-[#d9d9d9] flex items-end">
        <img src="assets/stripes.png" class="h-[400px] w-full">
    </div>
</div>

<!-- FORMULÁRIO DE LOGIN -->
<div class="bg-white rounded-2xl shadow-lg p-8 fixed top-[100px] w-full max-w-[420px] left-[calc(50%-50px)]"
    *ngIf="step == 'login'" [formGroup]="formLogin">
    <div class="text-3xl">Bem-vindo(a)</div>
    <div class="text-gray-500">Acesse sua conta com e-mail e senha</div>
    <app-input placeholder="E-mail*" class="mt-4" formControlName="email" />
    <app-input placeholder="Senha*" formControlName="senha" type="password" />
    <div class="text-primary fonte-1 font-bold cursor-pointer underline -mt-2 ps-2">Esqueci minha senha</div>
    <button class="w-full mt-4 flex espaco-3 text-white justify-between px-4 font-medium"  
    (click)="login()">
        ENTRAR
        <mat-icon fontIcon="arrow_circle_right" class="w-[40px] h-[40px] text-[26px]" />
    </button>
    <div
        class="border border-1 border-[#072331] mt-4 p-2 rounded fonte-1 flex justify-between items-center cursor-pointer">
        Entrar com Facebook
        <img src="assets/fb_icon.png" width="22px">
    </div>
    <div
        class="border border-1 border-[#072331] mt-1 p-2 rounded fonte-1 flex justify-between items-center cursor-pointer">
        Entrar com Google
        <img src="assets/google_icon.png" width="22px">
    </div>
    <hr class="my-4 text-primary" color="#072331">
    <div class="flex espaco-1 fonte-1 text-gray-500">
        Ainda não tem conta?
        <div class="font-bold underline cursor-pointer italic text-primary" (click)="step='registro'">Fazer Cadastro
        </div>
    </div>
</div>


<!-- FORMULÁRIO DE REGISTRO -->
<div class="bg-white rounded-2xl shadow-lg p-8 fixed top-[100px] w-full max-w-[420px] left-[calc(50%-50px)]"
    *ngIf="step == 'registro'" [formGroup]="formRegistro">
    <div class="text-3xl">Bem-vindo(a)</div>
    <div class="text-gray-500">Faça seu registro</div>
    <app-input placeholder="E-mail*" class="mt-4" formControlName="email" />
    <app-input placeholder="Senha*" formControlName="senha" type="password" />
    <app-input placeholder="Confirmar Senha*" formControlName="confirmaSenha" type="password" />
    <app-input placeholder="Telefone" class="mb-2" />
    <ngx-recaptcha2 [siteKey]="siteKey" formControlName="captcha_response" />
    <button class="w-full mt-4 flex espaco-3 text-white justify-between px-4 font-medium"
        [disabled]="formRegistro.invalid" (click)="registraUsuario()">
        REGISTRAR
        <mat-icon fontIcon="arrow_circle_right" class="w-[40px] h-[40px] text-[26px]"/>
    </button>
    <div class="mt-2 flex items-center espaco-3 fonte-1 font-bold underline cursor-pointer italic text-primary"
        (click)="step='login'"><mat-icon fontIcon="arrow_circle_left" />Voltar
    </div>

    {{formRegistro.errors}}

</div>
<button class="accent rounded-full px-5" (click)="adicionarEvento()">Adicionar Evento</button>

<div *ngIf="eventos" class="bg-white rounded p-4 mt-4 w-full overflow-auto custom-scroll">
    <table mat-table [dataSource]="eventos">
        <ng-container matColumnDef="data">
            <th mat-header-cell *matHeaderCellDef>Data</th>
            <td mat-cell *matCellDef="let evento">
                {{evento.data | date : 'shortDate'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="evento">
            <th mat-header-cell *matHeaderCellDef>Movimentação</th>
            <td mat-cell *matCellDef="let evento">
                <div [classList]="evento.movimento == 'Compra' ? 'text-blue-500' : 'text-orange-500'">{{evento.movimento}}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="ativo">
            <th mat-header-cell *matHeaderCellDef>Ativo</th>
            <td mat-cell *matCellDef="let evento">
                {{evento.ticker}}
            </td>
        </ng-container>


        <ng-container matColumnDef="preco">
            <th mat-header-cell *matHeaderCellDef>Preço Un.</th>
            <td mat-cell *matCellDef="let evento">
                R$ {{evento.preco_unitario | number : '1.2'}}
            </td>
        </ng-container>

        <ng-container matColumnDef="valor_operacao">
            <th mat-header-cell *matHeaderCellDef>Valor Operação</th>
            <td mat-cell *matCellDef="let evento">
                R$ {{evento.valor_operacao | number : '1.2'}}
            </td>
        </ng-container>

    
        <tr mat-header-row *matHeaderRowDef="colunas"></tr>
        <tr mat-row *matRowDef="let evento; columns: colunas"></tr>
    
    </table>
</div>
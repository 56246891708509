import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { AdicionarEventoComponent } from './adicionar-evento/adicionar-evento.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { CommonModule } from '@angular/common';
import { getRouteData } from '../../../common/route-utils';
import { ActivatedRoute } from '@angular/router';
import { BackendService } from '../../../services/backend.service';

@Component({
  selector: 'app-eventos-admin',
  standalone: true,
  imports: [MatDialogModule, MatTableModule, MatSortModule, CommonModule],
  templateUrl: './eventos-admin.component.html',
  styleUrl: './eventos-admin.component.scss'
})
export class EventosAdminComponent  implements OnInit, OnDestroy{

  constructor(private dialog: MatDialog, private route: ActivatedRoute, private svc: BackendService){}
  ngOnInit(): void {
    this.eventos = getRouteData(this.route)
  }
  
  ngOnDestroy(): void {
  }
  colunas = ['data', 'ativo', 'evento', 'preco', 'valor_operacao'];
  eventos = [ ];

  adicionarEvento(){
    this.dialog.open(AdicionarEventoComponent).afterClosed().subscribe({
      next: r => {
        this.svc.call<any>('movimentacoes-globais').subscribe({
          next: r => {this.eventos = r},
          error: e => {}
        })
      },
      error: e=> {}
    })
  }
}

import { Component, OnInit } from '@angular/core';
import { TextareaComponent } from "../../common/textarea/textarea.component";
import { ActivatedRoute } from '@angular/router';
import { MensagemGlobal } from '../../models/MensagemGlobal';
import { getRouteData } from '../../common/route-utils';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { BackendService } from '../../services/backend.service';

@Component({
  selector: 'app-mensagens-admin',
  standalone: true,
  imports: [TextareaComponent, ReactiveFormsModule, MatIconModule, CommonModule],
  templateUrl: './mensagens-admin.component.html',
  styleUrl: './mensagens-admin.component.scss'
})
export class MensagensAdminComponent implements OnInit{
  constructor(private route: ActivatedRoute, private formBuilder:FormBuilder, private svc: BackendService){}

  mensagem: MensagemGlobal;
  form: FormGroup;
  ngOnInit(): void {
    this.mensagem = getRouteData(this.route);
    console.log(this.mensagem)
    this.form = this.formBuilder.group({
      texto: [this.mensagem.texto]
    })
  }

  toggleMensagemAtiva(ativo: boolean){
    const url = ativo ? 'desativar-mensagem' : 'ativar-mensagem';
    this.svc.call(url).subscribe({
      next: r => {this.mensagem.ativo = !ativo},
      error: e => {}
    })
  }

  gravarMensagem(){
    this.svc.call('gravar-mensagem', this.form.getRawValue()).subscribe({
      next: r=> {console.log(r)},
      error: e=> {console.log(e)}
    })
  }

}

<mat-dialog-content class="primary adicionar-lancamento-frame ">
    <div class=" flex justify-center items-center flex-col espaco-4 py-2" [formGroup]="form">
        <img src="assets/logo_full.png" width="160">
        <div class="fonte-1 text-semibold text-white mt-2">Adicionar lançamento</div>
        <div class="grid grid-cols-2 espaco-4">
            <input type="date" class="text-[14px]" formControlName="data">
            <app-select [options]="tipoMovimentacao" formControlName="movimento"
                class="w-full max-h-[36px] text-[14px]" [rounded]="false" />

            <app-autocomplete [options]="app.tickers" class="max-h-[36px] w-full text-[14px]" placeholder="Ticker" formControlName="ticker"/>
            <app-input-alt placeholder="Preço Unitário" classInput="w-full text-[14px]" formControlName="preco_unitario"/>
            <app-input-alt placeholder="Valor Operação" classInput="w-full text-[14px] col-span-2" formControlName="valor_operacao"/>
        </div>
        <button class="accent rounded-full w-[130px] mt-2" (click)="adicionarMovimentacao()" [disabled]="form.invalid">Lançar</button>
    </div>

</mat-dialog-content>
import { inject } from "@angular/core";
import { BackendService } from "../services/backend.service";
import { AppService } from "../services/app.service";
import { Router } from "@angular/router";
import { firstValueFrom, from, map, of, Subscription } from "rxjs";
import { KeycloakService } from "keycloak-angular";
import { environment } from "../../environments/environment";

export async function appInitializer(svc: BackendService, app: AppService, router: Router, keycloak: KeycloakService) {

  await keycloak.init({
    config: {
      url: environment.KEYCLOAK_URL,
      realm: environment.KEYCLOAK_REALM,
      clientId: environment.KEYCLOAK_CLIENT_ID
    },
    initOptions: {
      // checkLoginIframe: false,
      redirectUri: `${environment.INFOAPP_URL}/dashboard`,
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri:
        window.location.origin + '/assets/silent-check-sso.html',
    },
    enableBearerInterceptor: true,
  });

  let r = await firstValueFrom(svc.call<any>('app-config'));
  if (r) {
    app.usuario = r['usuario'];
    app.tickers = r['tickers'];
    app.corretoras = r['corretoras'];
  }
  app.loaded = true;
  console.log(app.usuario)
}


import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-post-login',
  standalone: true,
  imports: [],
  templateUrl: './post-login.component.html',
  styleUrl: './post-login.component.scss'
})
export class PostLoginComponent implements OnInit{

  constructor(private auth: AuthService){}

  ngOnInit(): void {
    this.auth.setUser();
  }
}

import { Component } from '@angular/core';
import { selectColumns, xlsxToJson } from '../../../common/xlsx-utils';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { BackendService } from '../../../services/backend.service';
import { Router } from '@angular/router';
import { convertCurrency } from '../../../common/currency-utils';
import { MatStepperModule } from '@angular/material/stepper';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { ImportadorAtivos } from './importador-ativos';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

interface arquivo {
  arquivo: File;
  status: string;
  transacoesImportadas: number;
}

interface ArquivosCarregados {
  negociacao: arquivo[],
  movimentacao: arquivo[]
}

@Component({
  selector: 'app-importar-ativos',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatStepperModule, ReactiveFormsModule],
  templateUrl: './importar-ativos.component.html',
  styleUrl: './importar-ativos.component.scss',
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }]
})
export class ImportarAtivosComponent {

  constructor(private svc: BackendService, private router: Router, private builder :FormBuilder) { }

  arquivosCarregados: ArquivosCarregados = {
    'movimentacao': [],
    'negociacao': []
  }
  transacoes: any = [];
  corretoras = new Set<string>();

  importador = new ImportadorAtivos();
  step3Form: FormGroup = this.builder.group({
    importouComSucesso: [false, [Validators.requiredTrue]]
  })

  async extratoCarregado($event, tipo: string) {
    const files = $event.target.files;
    let a = this.arquivosCarregados[tipo];
    
    for (let index = 0; index < files.length; index++) {
      const i = (a.push({ arquivo: files[index], status: 'processando' })) - 1 //Ele retorna o tamanho do array atual, por isso subtrai -1
      let extrato = await this.importador.extrair(a[i].arquivo, tipo);
      a[i].status = extrato == null ? 'falha' : 'sucesso';
      if (extrato) {
        this.step3Form.patchValue({importouComSucesso: true})
        this.transacoes.push(...extrato['movimentacoes']);
        this.corretoras = new Set([...this.corretoras, ...extrato['corretoras']]);
        a[i].transacoesImportadas = extrato['movimentacoes'].length;
        // a[i].transacoes = extrato['movimentacoes'];
        // a[i].corretoras = extrato['corretoras'];
      }
    }
  }

  importarTransacoes() {
    
    this.svc.call('importar-transacoes-ativos', { movimentacoes: this.transacoes, corretoras: Array.from(this.corretoras)}).subscribe({
      next: r => {
        console.log(r);
        this.router.navigate(['ativos-brasil', 'movimentacoes'])
      },
      error: e => { console.log(e) }
    })
  }

  zerarAtivos() {
    this.svc.call('apagar-transacoes').subscribe({
      next: r => { console.log(r) },
      error: e => { }
    })
  }

  importarAtivosB3() {
    window.open('https://b3Investidorcer.b2clogin.com/b3Investidorcer.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_FINTECH&client_id=8f79a1a1-b78c-477c-aa1a-27d2aedc6aac&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fwww.investidor.b3.com.br&scope=openid&response_type=code&prompt=login'
      , '_blank'
    )
  }
}

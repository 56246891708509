import { Routes } from '@angular/router';
import { DashboardComponent } from './routes/dashboard/dashboard.component';
import { ImportarAtivosComponent } from './routes/ativos-brasil/importar-ativos/importar-ativos.component';
import { AtivosComponent } from './routes/ativos-brasil/ativos/ativos.component';
import { DividendosComponent } from './routes/ativos-brasil/dividendos/dividendos.component';
import { RebalanceamentoComponent } from './routes/ativos-brasil/rebalanceamento/rebalanceamento.component';
import { DetalhesAtivosComponent } from './routes/ativos-brasil/detalhes-ativos/detalhes-ativos.component';
import { BensComponent } from './routes/bens/bens/bens.component';
import { AdicionarBemComponent } from './routes/bens/adicionar-bem/adicionar-bem.component';
import { LoginComponent } from './routes/login/login.component';
import { inject } from '@angular/core';
import { BackendService } from './services/backend.service';
import { MovimentacoesComponent } from './routes/ativos-brasil/movimentacoes/movimentacoes.component';
import { EventosAdminComponent } from './routes/admin/eventos-admin/eventos-admin.component';
import { UsuariosAdminComponent } from './admin/usuarios-admin/usuarios-admin.component';
import { MensagensAdminComponent } from './admin/mensagens-admin/mensagens-admin.component';
import { AssinaturasAdminComponent } from './admin/assinaturas-admin/assinaturas-admin.component';
import { SugestoesAdminComponent } from './admin/sugestoes-admin/sugestoes-admin.component';
import { TermosComponent } from './routes/termos/termos.component';
import { AreaComumComponent } from './structure/area-comum/area-comum.component';
import { AreaLogadaComponent } from './structure/area-logada/area-logada.component';
import { PostLoginComponent } from './routes/post-login/post-login.component';
import { RouteGuard } from './services/route-guard';
import { HomeComponent } from './routes/home/home.component';
import { RendaFixaComponent } from './routes/renda-fixa/renda-fixa.component';
import { TesteComponent } from './routes/teste/teste.component';

export const routes: Routes = [
    {path: '', component: AreaComumComponent, children: [
        {path: '', redirectTo: '/home', pathMatch: 'full'},
        // {path: 'login', component: LoginComponent},
        {path: 'home' , component: HomeComponent},
        {path: 'termos', component: TermosComponent},
        {path: 'post-login', component: PostLoginComponent},
        {path: 'teste', component: TesteComponent}
    ]},
    
    {path: '', component: AreaLogadaComponent, canActivate:[RouteGuard],  children: [
        {path: 'dashboard', component: DashboardComponent, resolve: {value : () => inject(BackendService).call('dashboard')}},
        {path: 'ativos-brasil', children: [
            {path: 'importar-ativos', component:  ImportarAtivosComponent},
            {path: 'movimentacoes', component:  MovimentacoesComponent, resolve: {value: () => inject(BackendService).call('movimentacoes')}},
            {path: 'ativos', component:  AtivosComponent, resolve: {value: () => inject(BackendService).call('ativos')}},
            {path: 'dividendos', component:  DividendosComponent, resolve: {value: () => inject(BackendService).call('dividendos')}},
            {path: 'rebalanceamento', component:  RebalanceamentoComponent, resolve: {value : () => inject(BackendService).call('rebalanceamento')}},
            {path: 'detalhes-ativos', component:  DetalhesAtivosComponent},
        ]},
        {path: 'renda-fixa', component: RendaFixaComponent},

        {path: 'bens', children: [
            {path: 'meus-bens', component:  BensComponent, resolve: {value:   () => inject(BackendService).call('meus-bens')}},
            {path: 'adicionar-bem', component:  AdicionarBemComponent},
        ]},
        {path: 'admin', children: [
            {path: 'eventos-admin' , component: EventosAdminComponent, resolve: {value: () => inject(BackendService).call('movimentacoes-globais')}},
            {path: 'usuarios-admin' , component: UsuariosAdminComponent, resolve: {value: () => inject(BackendService).call('usuarios')}},
            {path: 'mensagens-admin' , component: MensagensAdminComponent, resolve: {value: () => inject(BackendService).call('mensagens-globais')}},
            {path: 'assinaturas-admin' , component: AssinaturasAdminComponent},
            {path: 'sugestoes-admin' , component: SugestoesAdminComponent, resolve: {value: () => inject(BackendService).call('sugestoes')}},
        ]}
    ]}
];

import { selectColumns, xlsxToJson } from "../../../common/xlsx-utils";

export interface Extrato {
    movimentacoes: any[];
    corretoras: Set<string>;
  }
  enum mov {
    //0=entrada/saida, 1=data, 2=movimentacao, 3=produto/ticker, 4=corretora, 5=quantidade, 6=preco_unitario, 7=valor_operacao 
    entrada_saida, data, movimentacao, ticker, corretora, quantidade, precoUnitario, valorOperacao
  }
  enum neg {
    //0=data, 1=movimentacao, 2=mercado, 3=prazo/vencimento, 4=corretora, 5=ticker, 6=quantidade, 7=preco_unitario, 8=valor_operacao
    data, movimentacao, mercado, prazo, corretora, ticker, quantidade, preco_unitario, valor_operacao
  }
  
export class ImportadorAtivos {

  i = {
    'movimentacao' : {entrada_saida: 0, data: 1, movimentacao: 2, ticker: 3, corretora: 4, quantidade: 5, preco_unitario: 6, valor_operacao: 7},
    'negociacao' : {data: 0, movimentacao: 1, mercado: 2, prazo:3, corretora:4 , ticker:5, quantidade:6, preco_unitario:7, valor_operacao:8}
  }


  public async extrair(file: File, tipo: string){
    let rows = await xlsxToJson(file) as any[]; //data, movimentacao, mercado, prazo/vencimento, corretora, ticker, quantidade, preco, valor
    if(!this.isExtratoValido(rows, tipo)) return null
    rows.splice(0, 1);
    let extrato: Extrato = { movimentacoes: [], corretoras: new Set() };
    const t = this.i[tipo]; //Helper para os índices serem nomes e não números
    
    rows.forEach(row => {
        if (this.isTransacaoRelevante(row, tipo)) {
          let movimento = row[t.movimentacao];
          
          let ticker = ''; // Em caso de Direitos de Subscrição - Exercido, os tickers com qualquer final são convertidos para 11
            if(tipo == 'movimentacao' && row[t.movimentacao] == 'Direitos de Subscrição - Exercido')
              ticker = this.transformaTickerFinal11(row[t.ticker])
            else
              ticker = this.isRendaFixa(row[t.ticker]) ? row[t.ticker] : row[t.ticker].match(/\w+/)[0];

            let preco_unitario = Number(row[t.preco_unitario]) ? row[t.preco_unitario] : null;
            let valor_operacao = Number(row[t.valor_operacao]) ? row[t.valor_operacao] : null;
            extrato.movimentacoes.push({
                movimento, ticker, data: row[t.data], quantidade: row[t.quantidade], preco_unitario,
                corretora: row[t.corretora], valor_operacao, origem: tipo
            })
            if (!extrato.corretoras.has(row[t.corretora]))extrato.corretoras.add(row[t.corretora]);
        }
    })
    return extrato
  }

  private transformaTickerFinal11(ticker: string){
    const digitosTicker = ticker.match(/^[A-Z]{3,6}([0-9]{1,2}) /)![1]; //Extrai os digitos numericos do ticker
    console.log(digitosTicker)
    return ticker.match(/\w+/)![0].replace(digitosTicker, '11');

  }

  private isTransacaoRelevante(row: any[], tipo: string){
    return tipo == 'movimentacao' ?  this.isMovimentacaoRelevante(row) : this.isNegociacaoRelevante(row)
  }

  private isExtratoValido(rows: any[], tipo: string) {
    const colunasPorTipo = {
      movimentacao : ["Entrada/Saída", "Data", "Movimentação", "Produto", "Instituição", "Quantidade", "Preço unitário", "Valor da Operação"],
      negociacao : ["Data do Negócio",	"Tipo de Movimentação", "Mercado",	"Prazo/Vencimento",	"Instituição",	"Código de Negociação",	"Quantidade",	"Preço",	"Valor"]
    }
    const temConteudo = rows.length > 2; //Header e pelo menos 1 linha
    const temColunasCertas = JSON.stringify(rows[0]) == JSON.stringify(colunasPorTipo[tipo]);
    return temConteudo && temColunasCertas;
  }

  private isMovimentacaoRelevante(row: any[]) { // É relevante para proventos, renda fixa e eventos de ativo
    return this.isRendaFixa(row[mov.ticker]) || this.isProvento(row[mov.movimentacao]) || this.isEventoAtivo(row[mov.movimentacao])
  }

  private isEventoAtivo(movimentacao: string) {
    return ['Desdobro', 'Grupamento', 'Bonificação em Ativos', 'Amortização', 'Direitos de Subscrição - Exercido'].includes(movimentacao)
  }
  private isProvento(movimentacao: string) {
    return ['Rendimento', 'Juros Sobre Capital Próprio', 'Dividendo'].includes(movimentacao);
  }
  private isRendaFixa(ticker: string) {
    return ticker.match(/.*Tesouro.*/);
  }

  private isTickerValido(ticker: string) {
    const tickersValidos = [3, 4, 5, 6, 11];
    const digitosTicker = ticker.match(/^[A-Z]{3,6}([0-9]{1,2})/); //Extrai os digitos numericos do ticker
    return (digitosTicker && (tickersValidos.includes(Number(digitosTicker[1]))));
  }

  private isNegociacaoRelevante(row: any[]) {
    return ['Mercado à Vista', 'Mercado Fracionário'].includes(row[neg.mercado]) && this.isTickerValido(row[neg.ticker]) 
  }



}

<div [classList]="profileClasses.join(' ')" >
    
    <div class="flex flex-row">
        <div class="flex items-center espaco-3 justify-center w-full" (click)="openProfile()">
            <mat-icon fontIcon="account_circle"
                class="!w-[40px] !h-[40px] text-[40px] transition-all" />
            <div class="fonte-1">{{app.usuario?.nome}}</div>
        </div>
        <button (click)="logout()" *ngIf="profileOpen">Logout</button>
    </div>

    <div *ngIf="profileOpen" class="flex flex-col">
        <a (click)="abrirMeusDados()">Meus Dados</a>
        <a (click)="alterarSenha()">Alterar Senha</a>
        <a (click)="abrirEnviarSugestao()">Enviar Sugestão</a>
        <a>Termos de Uso</a>
        <a>Alterar Senha</a>
    </div>
</div>
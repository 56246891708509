import { Component, OnInit } from '@angular/core';
import { getRouteData } from '../../common/route-utils';
import { ActivatedRoute } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { BackendService } from '../../services/backend.service';
import { CommonModule } from '@angular/common';
import { Sugestao } from '../../models/Sugestao';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmacaoComponent } from '../../common/confirmacao/confirmacao.component';

@Component({
  selector: 'app-sugestoes-admin',
  standalone: true,
  imports: [MatIconModule, CommonModule],
  templateUrl: './sugestoes-admin.component.html',
  styleUrl: './sugestoes-admin.component.scss'
})
export class SugestoesAdminComponent implements OnInit {

  constructor(private route: ActivatedRoute, private svc: BackendService, private dialog: MatDialog) { }
  sugestoes: Sugestao[];
  ngOnInit(): void {
    this.sugestoes = getRouteData(this.route);
    console.log(this.sugestoes)
  }

  lerSugestao(id: number) {
    console.log(id)
    let sub = this.svc.call('ler-sugestao', { id }).subscribe({
      next: r => {
        let s = this.sugestoes.find(s => s.id == id);
        s!.lida = true;
        sub.unsubscribe();

      },
      error: e => { console.log(e) }
    })
  }

  abrirConfirmacao(id: number) {
    let sub = this.dialog.open(ConfirmacaoComponent, {data: {titulo: 'Remover Sugestão', mensagem: 'Tem certeza que deseja remover esta sugestão?'}}).afterClosed().subscribe(
      c => {
        if (c) {
          let sub2 = this.svc.call('remover-sugestao', { id }).subscribe({
            next: r => { 
              let s = this.sugestoes.find(s => s.id == id);
              s!.visivel = false;
            },
            error: e => { }
          })
        }
      }
    )
  }
}

<div class="primary rounded-2xl flex flex-col items-center text-white px-4 py-6 max-w-[360px] relative cursor-pointer"
    (mouseover)="mouseOverCard = true" (mouseout)="mouseOverCard=false" (click)="abrirDialog()">
    <div class="text-xl font-bold">Tesouro Selic 2027</div>
    <div class="mt-4 text-xl">R$290.000,00</div>
    <div>Clear Corretora</div>

    @if (mouseOverCard) {
    <div class="absolute right-5 top-5 edit" >
        <mat-icon fontIcon="edit" />
    </div>
    }
</div>
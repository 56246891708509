import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';


interface confirmacaoData{
  titulo: string;
  mensagem: string;
}

@Component({
  selector: 'app-confirmacao',
  standalone: true,
  imports: [MatDialogModule],
  templateUrl: './confirmacao.component.html',
  styleUrl: './confirmacao.component.scss'
})
export class ConfirmacaoComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: confirmacaoData){

  }
}

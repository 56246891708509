import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';

export interface Links {
  pai: string,
  filho: string;
}

@Component({
  selector: 'app-breadcrumb',
  standalone: true,
  imports: [RouterModule, CommonModule],
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.scss'
})
export class BreadcrumbComponent implements OnInit, OnDestroy{

  constructor(private router: Router){}

  sub : Subscription;
  breacrumbUrl = {
    '/' : {pai: '', filho: ''},
    '/dashboard': {pai: 'Dashboard', filho: ''},
    '/ativos-brasil/importar-ativos' : {pai: 'Ativos Brasil', filho: 'Importar Ativos'},
    '/ativos-brasil/movimentacoes' : {pai: 'Ativos Brasil', filho: 'Movimentações'},
    '/ativos-brasil/ativos' : {pai: 'Ativos Brasil', filho: 'Ativos'},
    '/ativos-brasil/dividendos' : {pai: 'Ativos Brasil', filho: 'Dividendos'},
    '/ativos-brasil/rebalanceamento' : {pai: 'Ativos Brasil', filho: 'Rebalanceamento'},
    '/ativos-brasil/detalhes-ativos' : {pai: 'Ativos Brasil', filho: 'Detalhes dos Ativos'},
    '/admin/eventos-admin' : {pai : 'Admin', filho: 'Eventos Globais'},
    '/admin/usuarios-admin' : {pai : 'Admin', filho: 'Usuários'},
    '/admin/mensagens-admin' : {pai : 'Admin', filho: 'Mensagens'},
    '/admin/assinaturas-admin' : {pai : 'Admin', filho: 'Assinaturas'},
    '/admin/sugestoes-admin' : {pai : 'Admin', filho: 'Sugestões'},
  }

  links : Links = {pai: '', filho: ''};


  ngOnInit(): void {
    this.atualizaBreadcrumb(this.router.url)
    this.sub = this.router.events.subscribe(
      e => {
        if(e instanceof NavigationEnd) this.atualizaBreadcrumb(e.url);
      });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  atualizaBreadcrumb(url :string){
    if(url in this.breacrumbUrl) this.links = this.breacrumbUrl[url];
    else this.links = {pai: '', filho: ''}
  }


}

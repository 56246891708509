<mat-dialog-content>
    <div class="title">{{form.get('id')?.value ? 'Alterar' : 'Adicionar'}} Bem</div>

    <div class="card max-w-[720px]" [formGroup]="form">
        <div class="flex espaco-3">
            <app-input class="w-full" placeholder="Nome" formControlName="nome" />
            <app-input placeholder="Preço" formControlName="valor" />
        </div>
        <app-textarea placeholder="Descrição" formControlName="descricao" />

        <app-select label="Categoria" [options]="options" formControlName="categoria"/>

        <div class="w-full flex justify-end espaco-1">
            <button class="warn" (click)="removerBem()" *ngIf="this.form.get('id')?.value">Excluir</button>
            <button (click)="adicionarBem()" [disabled]="form.invalid">Salvar</button>
        </div>
    </div>
</mat-dialog-content>

import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-input-alt',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './input-alt.component.html',
  styleUrl: './input-alt.component.scss',
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: InputAltComponent, multi: true}
  ]
})
export class InputAltComponent implements OnInit, AfterViewInit, ControlValueAccessor{
  constructor(private builder:FormBuilder){}

  ngOnInit(): void {
    this.form = this.builder.group({
      input : []
    })
  }

  ngAfterViewInit(): void {
    this.form.valueChanges.subscribe(
      val => {
        this.onChange(val['input']);
        this.inputChanged.emit(this.form.get('input')!.value)
      }
    )
  }
  @Input() placeholder = '';
  @Input() type = '';
  @Input() classInput = '';
  @Output() inputChanged = new EventEmitter();
  @Input()maxLength = 100;
  @Input()label = '';
  @Input()classLabel = '';

  form: FormGroup;
  onChange(val: string){}
  writeValue(obj: any): void {
    this.form.patchValue({input: obj})
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {
    if(isDisabled)this.form.get('input')?.disable()
  }
}

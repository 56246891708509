import { Component } from '@angular/core';
import { AtivosCardComponent } from '../ativos-brasil/ativos/ativos-card/ativos-card.component';
import { MatIconModule } from '@angular/material/icon';
import { RendaFixaCardComponent } from './renda-fixa-card/renda-fixa-card.component';

@Component({
  selector: 'app-renda-fixa',
  standalone: true,
  imports: [AtivosCardComponent, MatIconModule, RendaFixaCardComponent],
  templateUrl: './renda-fixa.component.html',
  styleUrl: './renda-fixa.component.scss'
})
export class RendaFixaComponent {

}

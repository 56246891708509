import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-area-comum',
  standalone: true,
  imports: [RouterModule, RouterModule],
  templateUrl: './area-comum.component.html',
  styleUrl: './area-comum.component.scss'
})
export class AreaComumComponent {
  constructor(private auth: AuthService, private router : Router){}

  async login(){
    this.auth.isLoggedIn() ?  this.router.navigate(['/dashboard']): await this.auth.login();
    
  }
}

import { CommonModule, DecimalPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ativos-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ativos-card.component.html',
  styleUrl: './ativos-card.component.scss',
  providers: [DecimalPipe]
})
export class AtivosCardComponent implements OnInit{

  constructor( private decimal: DecimalPipe){}
  @Input() class = '';
  @Input() topTitle = '';
  @Input() topValue ;
  @Input() bottomValue ;
  @Input() bottomText = '';
  @Input() topFormat = 'R$ value';

  topFormated = '';

  topValueFormated = '';
  ngOnInit(): void {
    let topValue = this.topValue;
    if(typeof this.topValue === 'number') topValue = this.decimal.transform(this.topValue, '1.2');
    this.topFormated = this.topFormat.replace('value', topValue)
  }
}

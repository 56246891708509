import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { AppService } from '../../services/app.service';
import { MatIconModule } from '@angular/material/icon';
import { InputComponent } from '../../common/input/input.component';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { BackendService } from '../../services/backend.service';

@Component({
  selector: 'app-modal-meus-dados',
  standalone: true,
  imports: [MatDialogModule, MatIconModule, InputComponent, ReactiveFormsModule],
  templateUrl: './modal-meus-dados.component.html',
  styleUrl: './modal-meus-dados.component.scss'
})
export class ModalMeusDadosComponent implements OnInit{

  constructor(private dialog: MatDialog, public app : AppService, private builder: FormBuilder, private svc: BackendService){}

  form: FormGroup;

  ngOnInit(): void {
    this.form = this.builder.group({
      nome: [this.app.usuario?.nome],
      email: {value: this.app.usuario?.email, disabled: true},
      telefone: [this.app.usuario?.telefone],
    })
  }

  atualiza_usuario(){
    this.svc.call<any>('atualiza-usuario', this.form.getRawValue()).subscribe({
      next: r=> {console.log(r); this.app.usuario!.nome = r.nome; this.app.usuario!.telefone = r.telefone},
      error: e=> {console.log(e)}
    })
  }
}

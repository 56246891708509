import { Component } from '@angular/core';
import { MatTableModule } from '@angular/material/table';

@Component({
  selector: 'app-teste',
  standalone: true,
  imports: [MatTableModule],
  templateUrl: './teste.component.html',
  styleUrl: './teste.component.scss'
})
export class TesteComponent {

  data : any[] = [
    {nome: 'Alan', idade : 35 , sexo : 'M'},
    {nome: 'Alan', idade : 35 , sexo : 'M'},
    {nome: 'Alan', idade : 35 , sexo : 'M'},
    {nome: 'Alan', idade : 35 , sexo : 'M'},
    {nome: 'Alan', idade : 35 , sexo : 'M'},
  ]

  colunas = ['nome', 'idade', 'sexo']
}

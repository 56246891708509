import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BackendService } from '../../services/backend.service';
import { AppService } from '../../services/app.service';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ModalSugestaoComponent } from '../modal-sugestao/modal-sugestao.component';
import { ModalMeusDadosComponent } from '../modal-meus-dados/modal-meus-dados.component';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-profile-menu',
  standalone: true,
  imports: [MatIconModule, CommonModule, MatDialogModule],
  templateUrl: './profile-menu.component.html',
  styleUrl: './profile-menu.component.scss'
})


export class ProfileMenuComponent implements OnInit{

  profileOpen = false;
  profileClasses = ['profile', 'profile-closed']

  constructor(private svc: BackendService, public app: AppService, private dialog: MatDialog, private router: Router, private auth: AuthService){}

  ngOnInit(): void {
  }

  openProfile() {
    if (!this.profileOpen) {
      const i = this.profileClasses.findIndex(c => c == 'profile-closed');
      this.profileClasses.splice(i, 1);
      this.profileClasses.push('profile-open');
      setTimeout(() => this.profileOpen = true, 300)
      
    }
    else {
      const i = this.profileClasses.findIndex(c => c == 'profile-open');
      this.profileClasses.splice(i, 1);
      this.profileClasses.push('profile-closed');
      this.profileOpen = false;
    }
  }

  async logout(){
    await this.auth.logout();
    this.router.navigateByUrl('/')
  }

  abrirEnviarSugestao(){
    this.dialog.open(ModalSugestaoComponent);
  }

  abrirMeusDados(){
    this.dialog.open(ModalMeusDadosComponent);
  }

  alterarSenha(){
    const url = `${environment.KEYCLOAK_URL}/realms/${environment.KEYCLOAK_REALM}/protocol/openid-connect/auth?client_id=${environment.KEYCLOAK_CLIENT_ID}&redirect_uri=${document.location.href}&response_type=code&scope=openid&kc_action=UPDATE_PASSWORD`
    window.location.href = url;
  }

  
}

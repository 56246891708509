import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { TextareaComponent } from '../../common/textarea/textarea.component';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { BackendService } from '../../services/backend.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-modal-sugestao',
  standalone: true,
  imports: [MatDialogModule, TextareaComponent, ReactiveFormsModule, CommonModule],
  templateUrl: './modal-sugestao.component.html',
  styleUrl: './modal-sugestao.component.scss'
})
export class ModalSugestaoComponent implements OnInit{

  constructor(public dialog: MatDialog, private formBuilder : FormBuilder, private svc: BackendService){}

  form: FormGroup;
  step = 1;
  ngOnInit(){
    this.form = this.formBuilder.group({
      texto: ['', [Validators.required, Validators.maxLength(300)]]
    })
  }

  enviarSugestao(){
    this.svc.call('adicionar-sugestao', this.form.getRawValue()).subscribe({
      next: r => {this.step = 2},
      error: e => {console.log(e)}
    })
  }
}

import { Usuario } from "./Usuario";

export class Bem {
    id;
    email_usuario: string = '';
    usuario: Usuario;
    nome: string = '';
    descricao: string = '';
    valor: number = 0;
    criacao: string;
    categoria: string;
}
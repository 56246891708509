import { SelectOption } from "./select/select.component";

export const categoriasBens : SelectOption[] = [
    {nome: 'Apartamento', id : 'apartamento'},
    {nome: 'Casa', id : 'casa'},
    {nome: 'Terreno', id : 'terreno'},
    {nome: 'Veículo', id : 'veiculo'},
    {nome: 'Aeronave', id : 'aeronave'},
    {nome: 'Embarcação', id : 'embarcacao'},
    {nome: 'Outros', id : 'outros'},    
]

// export const categoriasBensT : {} = {
//     apartamento : 'Apartamento',
//     casa: 'Casa',
//     terreno : 'Terreno',
//     veiculo: 'Veículo',
//     aeronova: 'Aeronave',
//     embaracao: 'Embarcação',
//     outros: 'Outros'
// }


import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SideMenuComponent } from './structure/side-menu/side-menu.component';
import { ProfileMenuComponent } from './structure/profile-menu/profile-menu.component';
import { AppService } from './services/app.service';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './routes/login/login.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { BreadcrumbComponent } from './structure/breadcrumb/breadcrumb.component';
import { KeycloakService } from 'keycloak-angular';
import { jwtDecode } from 'jwt-decode';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, SideMenuComponent, ProfileMenuComponent, CommonModule, LoginComponent, MatProgressSpinnerModule, BreadcrumbComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent 
  implements OnInit
{
  title = 'InfoAPP Investimentos';
  loggedIn = false;

  constructor(public app: AppService, 
    private keycloak: KeycloakService,
    private auth: AuthService
  ){}
  async ngOnInit(){
  
    console.log(this.keycloak.isLoggedIn())
  }

  
}

import * as XLSX from 'xlsx';


const headersMovimentacoes = ['referenceDate', 'movementType', 'market', 'dueDate', 'institution', 'ticker', 'quantity', 'price', 'value']

export async function xlsxToJson(file: File){
   return new Promise((resolve,reject) => {
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
        let arrayBuffer = fileReader.result as ArrayBuffer;
        var data = new Uint8Array(arrayBuffer);
        var arr = new Array();
        for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
        var bstr = arr.join("");
        var workbook = XLSX.read(bstr, {type:"binary"});
        var first_sheet_name = workbook.SheetNames[0];
        var worksheet = workbook.Sheets[first_sheet_name];
        resolve(XLSX.utils.sheet_to_json(worksheet,{raw:true , header: 1, range:0, blankrows: false}));
    }
    fileReader.readAsArrayBuffer(file);
   }) 
}

export function selectColumns(rows: any[], colNumbers: number[]){
    let parsedArray : any[] = [];
    rows.forEach(row => {
        let newRow: any[] = [];
        colNumbers.forEach(num => newRow.push(row[num]))
        parsedArray.push(newRow)
    });
    return parsedArray;
}
<div class="grid grid-investimentos mt-4">
    <!-- CARD 1 -->
    <div class="primary card text-white">
        <div class="flex justify-between items-center pb-2">
            <div class="fonte-3">{{data.card1.titulo}}</div>
            <!-- <div
                class="bg-white py-1 ps-3 pe-1 rounded-3xl text-primary flex items-center justify-center cursor-pointer">
                <img src="assets/bandeira_brasil.png" width="24" class="h-[18px]" />
                <mat-icon fontIcon="swap_horiz" class="text-[21px] font-bold flex pt-[2px]" />
            </div> -->
        </div>

        <!-- HISTORICO -->
        <table class="border-y border-gray-300 fonte-1 tabela-investimentos w-full">
            @for (linha of data.card1.historico; track $index) {
            <tr>
                <td>{{linha.name}}</td>
                <td class="text-right">{{linha.value | currency : 'BRL'}}</td>
            </tr>
            }
        </table>
        <div class="grid grid-cols-2">
            <div class="font-semibold">Total</div>
            <div class="font-semibold text-right">{{totalCard1 | currency : 'BRL'}}</div>
        </div>

        <!-- GRAFICO CARD 1 -->
        @if (data.card1.grafico) {
        <div class="flex justify-center w-full">
            <ngx-charts-pie-chart [results]="data.card1.grafico" [arcWidth]="0.5" [view]="[200,200]" [scheme]="colorSchemeVarious"/>
        </div>
        }
    </div>

    <div class="card rounded-xl col-span-2">
        <div class="grid grid-cols-2 h-min">
            <!-- CARD 2 -->
            <div>
                <div class="pb-2 w-full flex justify-center fonte-3 border-b border-gray-300">{{data.card2.titulo}}</div>
                <div class="flex justify-center w-full mt-4 border-e">
                    <ngx-charts-pie-chart [results]="data.card2.grafico" [labels]="true" [arcWidth]="0.5"
                        [doughnut]="true" [view]="[375, 300]" [scheme]="colorSchemeVarious"/>
                </div>
            </div>
            
            <!-- CARD 3 -->
            <div>
                <div class="pb-2 w-full flex justify-center fonte-3 border-b border-gray-300">{{data.card3.titulo}}</div>
                <div class="flex justify-center w-full mt-4">
                    <ngx-charts-pie-chart [results]="data.card3.grafico" [labels]="true" [arcWidth]="0.5"
                        [doughnut]="true" [view]="[375, 300]" [scheme]="colorSchemeVarious"/>
                </div>
            </div>
        </div>
    </div>
</div>
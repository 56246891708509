<div class="px-[10vw] mt-10">
    <div class="text-xl font-bold">Termos Gerais de Uso do InfoAPP Investimentos</div>
    <div class="bg-gray-200 rounded-xl p-4 mt-2 fonte-1">
        Este Termo de Uso estabelece as condições para o uso do aplicativo denominado "InfoApp" e visa garantir a
        proteção dos direitos autorais e de propriedade intelectual relacionados ao seu conteúdo.
        Ao utilizar o InfoApp, o usuário concorda com os termos e condições descritos a seguir:
    </div>

    <div class="fonte-1 mt-4">
        <div class="section">1. Propriedade Intelectual:</div>
        O InfoApp é protegido por direitos autorais e outras leis de propriedade intelectual. O autor ou titular dos
        direitos autorais do InfoApp (Infobolsa Investimentos - CNPJ: 46.188.613/0001-03) detém todos os direitos sobre
        o conteúdo do aplicativo, incluindo, mas não se limitando a, textos, funcionalidades, gráficos, interfaces e
        demais elementos que compõem o InfoApp.
        <br><br>
        <div class="section">2. Uso Pessoal e Licença de Uso:</div>
        O InfoApp é fornecido exclusivamente para uso pessoal e não comercial. O usuário recebe uma licença limitada,
        não exclusiva, intransferível e revogável para utilizar o InfoApp apenas para fins pessoais. O usuário concorda
        em não reproduzir, distribuir, modificar, adaptar, publicar, vender, licenciar, criar trabalhos derivados ou
        explorar comercialmente o InfoApp, no todo ou em parte, sem autorização expressa do Proprietário.
        <br><br>
        <div class="section">3. Restrições de Cópia e Distribuição:</div>
        É expressamente proibida a cópia, total ou parcial, do InfoApp, seja por meio de duplicação eletrônica,
        engenharia reversa, ou qualquer outro método. Qualquer tentativa de copiar ou reproduzir o InfoApp constitui
        violação dos direitos autorais e poderá resultar em ações legais.
        <br><br>
        <div class="section">4. Responsabilidade do Usuário:</div>
        O usuário é o único responsável pelo uso adequado do InfoApp e pela conformidade com as leis de direitos
        autorais aplicáveis. O InfoApp oferece funcionalidades para controle de investimentos e organização financeira,
        incluindo dados que podem ser utilizados para declarações fiscais, como o imposto de renda.
        O usuário deve conferir todos os dados gerados pelo InfoApp antes de utilizá-los para quaisquer fins legais,
        financeiros ou fiscais. O proprietário do InfoApp não se responsabiliza por erros de preenchimento, cálculos
        incorretos, ou quaisquer falhas nos dados apresentados. Qualquer uso indevido das informações e eventuais
        prejuízos resultantes são de responsabilidade exclusiva do usuário.
        <br><br>
        <div class="section">5. Isenção de Garantias:</div>
        O InfoApp é fornecido "no estado em que se encontra", sem quaisquer garantias expressas ou implícitas de
        adequação a uma finalidade específica, comercialização ou não violação de direitos de terceiros. O proprietário
        não garante que o InfoApp funcionará sem interrupções, erros ou que atenderá às expectativas do usuário.
        <br><br>
        <div class="section">6. Limitação de Responsabilidade:</div>
        O Proprietário não se responsabiliza por quaisquer danos diretos, indiretos, incidentais, especiais ou
        consequenciais decorrentes do uso ou da impossibilidade de uso do InfoApp, incluindo, mas não se limitando a,
        perdas financeiras, interrupção de negócios, perda de informações ou qualquer outro prejuízo.
        <br><br>
        <div class="section">7. Alterações no Aplicativo e Termos de Uso:</div>
        O Proprietário reserva-se o direito de fazer alterações no InfoApp, incluindo adição, remoção ou modificação de
        funcionalidades e termos deste Termo de Uso, a qualquer momento, sem aviso prévio. Recomenda-se que o usuário
        reveja periodicamente estes Termos para estar ciente de quaisquer atualizações.
        <br><br>
        <div class="section">8. Privacidade e Dados do Usuário:</div>
        O InfoApp pode coletar dados pessoais do usuário conforme descrito na Política de Privacidade. Ao utilizar o
        aplicativo, o usuário concorda com a coleta e uso de suas informações de acordo com a Política de Privacidade do
        InfoApp.

        <div class="section">9. Contato:</div>
        Em caso de dúvidas, reclamações ou questões relacionadas ao uso do InfoApp, o usuário pode entrar em contato com
        o suporte através dos canais de atendimento disponibilizados:
        WhatsApp: (35) 99900-0732
        E-mail: suporte&#64;infoapp.com.br
        <br><br>
        Ao utilizar o InfoApp, o usuário declara ter lido, entendido e concordado com este Termo de Uso.

    </div>
</div>
<div class="h-[100px] border border-b-1 flex items-center justify-between px-[10vw]">
    <img src="assets/logo_inv.png" class="md:w-[200px] w-[150px]">
    <button 
    class="custom border border-1 border-[#072331] px-4 py-2 rounded-full text-gray-700 font-medium hover:opacity-60 fonte-1 md:fonte-3" 
    (click)="login()" >Fazer Login</button>
</div>
<router-outlet></router-outlet>

<div class="fixed bottom-0 py-3 bg-white w-full flex justify-center text-gray-600 fonte-1">
    Copyright © 2024 Todos os direitos reservados  /   Privacidade   /   <a routerLink="/termos">Termos de uso</a>   /   Preferências de cookies
</div>
import { APP_INITIALIZER, ApplicationConfig, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { Router, provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { AppService } from './services/app.service';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { BackendService } from './services/backend.service';
import { appInitializer } from './common/app-initializer';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';


registerLocaleData(localePt);

// INICIALIZAÇÃO DO KEYCLOAK É FEITA DENTRO DO appInitializer
const initializeApp = (app: AppService, router: Router, svc: BackendService, keycloak: KeycloakService) => {
  return () =>
    appInitializer(svc, app, router, keycloak)
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideClientHydration(),
    provideAnimationsAsync(),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    importProvidersFrom(KeycloakAngularModule),
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppService, Router, BackendService, KeycloakService], multi: true },
    { provide: LOCALE_ID, useValue: 'pt-BR' }
  ]
};

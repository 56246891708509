import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable,  catchError,  take, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AppService } from './app.service';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root'
})
export class BackendService {
  constructor(private http: HttpClient, private router: Router, private app: AppService, 
    // private keycloak: KeycloakService
  ) { }

  private getEndpoint(key: string, append: string | null = null) {
    let endpoints: { [key: string]: { path: string, verb: string } } = {
      // 'registro': {path: 'registro', verb: 'POST'},
      // 'login': {path: 'login', verb: 'POST'},
      // 'logout': {path: 'logout', verb: 'GET'},
      'app-config': {path: 'app-config', verb: 'GET'},
      
      'dashboard' : {path: 'dashboard', verb: 'GET'},
      
      'adicionar-bem': {path: 'bens/adicionar-bem', verb: 'POST'},
      'atualizar-bem': {path: 'bens/atualizar-bem', verb: 'PATCH'},
      'remover-bem': {path: 'bens/remover-bem', verb: 'DELETE'},
      'meus-bens': {path: 'bens', verb: 'GET'},
      
      'ativos': {path: 'ativos', verb: 'GET'},
      'importar-transacoes-ativos': {path: 'ativos/importar-transacoes', verb: 'POST'},
      'movimentacoes': {path: 'ativos/movimentacoes', verb: 'GET'},
      'adicionar-movimentacao': {path: 'ativos/movimentacoes', verb: 'POST'},
      'atualizar-movimentacoes': {path: 'ativos/movimentacoes', verb: 'PUT'},
      'excluir-movimentacoes': {path: 'ativos/movimentacoes', verb: 'DELETE'},

      'dividendos' : {path: 'ativos/dividendos', verb : 'GET'},

      'rebalanceamento' : {path: 'ativos/rebalanceamento', verb : 'GET'},

      'movimentacoes-globais': {path:'admin/movimentacoes-globais', verb: 'GET'},
      'adicionar-movimentacao-global': {path:'admin/movimentacoes-globais', verb: 'POST'},
      
      'sugestoes': {path:'admin/sugestoes', verb: 'GET'},
      'ler-sugestao': {path:'admin/ler-sugestao', verb: 'POST'},
      'remover-sugestao': {path:'admin/remover-sugestao', verb: 'POST'},
      'adicionar-sugestao': {path:'sugestoes/adicionar-sugestao', verb: 'POST'},

      'usuarios' : {path: 'admin/usuarios', verb: 'GET'},
      'ativar-usuario' : {path: 'admin/ativar-usuario', verb: 'POST'},
      'desativar-usuario' : {path: 'admin/desativar-usuario', verb: 'POST'},
      'admin-alterar-senha' : {path: 'admin/alterar-senha', verb: 'POST'},

      'mensagens-globais' : {path: 'admin/mensagens', verb: 'GET'},
      'gravar-mensagem' : {path: 'admin/gravar-mensagem', verb: 'POST'},
      'ativar-mensagem' : {path: 'admin/ativar-mensagem', verb: 'GET'},
      'desativar-mensagem' : {path: 'admin/desativar-mensagem', verb: 'GET'},

      'atualiza-usuario' : {path: 'atualiza-usuario', verb: 'POST'},

    }
    let path = append ? `${environment.BACKEND_URL}/${endpoints[key].path}/${append}` : `${environment.BACKEND_URL}/${endpoints[key].path}`
    return { path, verb: endpoints[key].verb }

  }
  call<T>(key: string, payload?: any, append?: string) : Observable<T>{
    let endpoint = this.getEndpoint(key, append);
    return this.http.request<T>(endpoint.verb, endpoint.path, {body: payload, withCredentials: true}).pipe(catchError(error => this.handleError(error)), take(1))
  }

  handleError(error: any): Observable<never> {
    console.log(error)
    // if (error.status === 401) {
    //   this.app.usuario = null;
    //   this.router.navigate(['/login']);
    // }
    return throwError(() => error);
  }
}

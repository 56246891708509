import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { InputComponent } from '../../../../common/input/input.component';
import { InputAltComponent } from '../../../../common/input-alt/input-alt.component';
import { SelectComponent, SelectOption } from '../../../../common/select/select.component';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AppService } from '../../../../services/app.service';
import { AutocompleteComponent } from '../../../../common/autocomplete/autocomplete.component';
import { BackendService } from '../../../../services/backend.service';
import { Movimentacao } from '../../../../models/Movimentacao';
import * as lodash from 'lodash';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { tiposMovimentacao } from '../../../../common/tiposMovimentacao';

@Component({
  selector: 'app-adicionar-movimentacao',
  standalone: true,
  imports: [MatDialogModule, InputComponent, InputAltComponent, SelectComponent, ReactiveFormsModule, AutocompleteComponent, CommonModule, MatCheckboxModule],
  templateUrl: './adicionar-movimentacao.component.html',
  styleUrl: './adicionar-movimentacao.component.scss'
})
export class AdicionarMovimentacaoComponent implements OnInit {

  constructor(private builder: FormBuilder, public app: AppService, private svc: BackendService, private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public movimentacoes: Movimentacao[] = []) { }

  ngOnInit(): void {
    let movimentacao = this.movimentacoes?.length == 1 ? this.movimentacoes[0] : new Movimentacao();
    console.log(movimentacao)
    if (movimentacao.corretora) {
      movimentacao.id_corretora = (this.app.corretoras.find(c => c.nome == movimentacao.corretora.nome))!['id'];
    }
    this.form = this.builder.group({
      data: [movimentacao.data],
      movimento: [movimentacao.movimento],
      ticker: [movimentacao.ticker],
      quantidade: [movimentacao.quantidade],
      preco_unitario: [movimentacao.preco_unitario],
      id_corretora: [movimentacao.id_corretora],
      valor_operacao: [movimentacao.valor_operacao],
      adicionar_outras: [false]
    })
  }
  form: FormGroup;
  tiposMovimentacao = tiposMovimentacao;

  adicionarMovimentacao() {

    let payload = this.form.getRawValue();
    let url = 'adicionar-movimentacao';

    if (this.movimentacoes) {
      let movimentacoesEditadas = lodash.cloneDeep(this.movimentacoes);
      Object.entries(this.form.getRawValue()).forEach(item => {
        if (item[1]) { //Se não for vazio
          movimentacoesEditadas.forEach(movimento => movimento[item[0]] = item[1]);
        }
      });
      console.log(movimentacoesEditadas);
      payload = movimentacoesEditadas;
      url = 'atualizar-movimentacoes';
    }

    const sub = this.svc.call(url, payload).subscribe(
      {  
        next: r => {
          sub.unsubscribe();
          if (this.form.get('adicionar_outras')?.value){
            this.form.reset();
            this.form.get('adicionar_outras')?.setValue(true);
          }
          else
            this.dialog.closeAll()
        },
        error: e => { }
      }
    )

  }

}

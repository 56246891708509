import { Color, ScaleType } from '@swimlane/ngx-charts';

export const colorSchemeGreen: Color = {
    name: 'green',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#008000'],
  };

export const colorSchemeYellow: Color = {
    name: 'yellow',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#fabc05'],
  };

export const colorSchemeCyan: Color = {
    name: 'yellow',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#6ce5e8'],
  };

export const colorSchemeDonut: Color = {
    name: 'donut',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#ea4335', '#4285f3'],
  };

  export const colorSchemeVarious: Color = {
    name: 'various',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#4285f3', '#fabc05', '#23ba3c', '#1251ff', '#c11e40', '#804c61', '#4e8dc1', '#4cd5cf', '#4c058a', '#775434', '#541d57', '#81a554' , '#fffbc7', '#f4fd7d']
  }
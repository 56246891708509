<div class="flex justify-between items-end">
    <div class="flex espaco-4">
        <app-ativos-card topTitle="Ativo com maior percentual" topValue="AESE3" bottomText="da carteira"
            bottomValue="75" topFormat="value" />
        <app-ativos-card topTitle="Ativo com maior percentual" topValue="AESE3" bottomText="da carteira"
            bottomValue="75" topFormat="value" />
    </div>

    <div class="grid grid-cols-2 espaco-3 text-xl" [formGroup]="form">
        <div>Aporte ideal</div>
        <div>Aporte</div>
        <app-input-alt classInput="h-[50px] fonte-3 accent" formControlName="aporteIdeal" />
        <app-input-alt classInput="h-[50px]" formControlName="aporte" />
    </div>
</div>

<table class="mt-6">
    <tr>
        <th colspan="3">1</th>
        <th colspan="3" class="fonte-3 !text-center px-3 bold bg-gradient-to-r from-gray-400 to-indigo-200">Carteira Atual</th>
        <th colspan="1"></th>
        <th colspan="2" class="fonte-3 !text-center bold bg-[#E4D0A8]">Valores Ideais</th>
        <th colspan="4" class="fonte-3 !text-center bold bg-[#9AB4E5]">Ajuste de Posição</th>
    </tr>
    <tr>
        <th>Ativo</th>
        <th>Classe</th>
        <th>Cotação</th>


        <th>Qtd</th>
        <th class="!text-right">Total</th>
        <th class="!text-right">%</th>

        <th class="!text-center">Objetivo</th>
        <th>Qtd</th>
        <th>Total</th>

        <th>Qtd</th>
        <th>Total</th>
        <th>%</th>
        <th>Status</th>
    </tr>

    @for (ativo of rebalanceamento.corretoras['CLEAR CORRETORA - GRUPO XP'].ativos; track $index) {
        <tr>
            <td class="dark-bg">{{ativo.ticker}}</td>
            <td class="dark-bg">{{ativo.classe}}</td>
            <td class="dark-bg">{{ativo.cotacao}}</td>
    
            <td>{{ativo.quantidade}}</td>
            <td class="!text-right">{{ativo.valor_atual | currency : 'BRL'}}</td>
            <td class="!text-right">{{ativo.porcentagem}}</td>
    
            <td class="!text-center"><app-input-alt class="w-[80px]" [maxLength]="5"/></td>
            <td>33</td>
            <td>R$99,99</td>
    
            <td>40</td>
            <td>R$101,01</td>
            <td>10%</td>
            <td class="font-semibold">COMPRAR</td>            
        </tr>
    }
</table>
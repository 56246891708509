import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnInit, viewChild, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import {MatAutocomplete, MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Observable, map, startWith } from 'rxjs';

@Component({
  selector: 'app-autocomplete',
  standalone: true,
  imports: [MatAutocompleteModule, MatFormFieldModule, ReactiveFormsModule, CommonModule, MatInputModule],
  templateUrl: './autocomplete.component.html',
  styleUrl: './autocomplete.component.scss',
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: AutocompleteComponent, multi: true}
  ]
})
export class AutocompleteComponent implements OnInit, ControlValueAccessor{
  
  onChange(val: string){}
  writeValue(val: string): void {this.control.setValue(val)}
  registerOnChange(fn: any): void { this.onChange = fn}
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {}
  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  control = new FormControl('');
  @Input() options: string[] = [];
  @Input() class = '';
  @Input() placeholder = '';
  @Input() label = '';
  @Input() classLabel = '';
  filteredOptions: string[];

  ngOnInit(){
    this.filteredOptions = this.options.slice();
  }

  filter(): void {
    const filterValue = this.input.nativeElement.value.toLowerCase();
    this.filteredOptions = this.options.filter(o => o.toLowerCase().includes(filterValue));
  }

  checkEmpty($event){
    setTimeout(() => {if($event.target.value === '') this.onChange('')}, 100)
  }
}

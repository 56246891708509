<table mat-table [dataSource]="data">
    
    <ng-container matColumnDef="nome">
        <th mat-header-cell *matHeaderCellDef="">Nome</th>
        <td mat-cell *matCellDef="let item">{{item.nome}}</td>
    </ng-container>

    <ng-container matColumnDef="idade">
        <th mat-header-cell *matHeaderCellDef="">Idade</th>
        <td mat-cell *matCellDef="let item">{{item.idade}}</td>
    </ng-container>

    <ng-container matColumnDef="sexo">
        <th mat-header-cell *matHeaderCellDef="">Sexo</th>
        <td mat-cell *matCellDef="let item">{{item.sexo}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="colunas"></tr>
    <tr mat-row *matRowDef="let item; columns: colunas"></tr>
</table>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Usuario } from '../../models/Usuario';
import { getRouteData } from '../../common/route-utils';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule, DatePipe } from '@angular/common';
import { BackendService } from '../../services/backend.service';

@Component({
  selector: 'app-usuarios-admin',
  standalone: true,
  imports: [MatTableModule, MatSortModule, MatIconModule, CommonModule, DatePipe],
  templateUrl: './usuarios-admin.component.html',
  styleUrl: './usuarios-admin.component.scss'
})
export class UsuariosAdminComponent implements OnInit{
  constructor(private route: ActivatedRoute, private svc: BackendService){}


  colunas = ['nome', 'email', 'criacao', 'ativo']
  usuarios: Usuario[];
  usuariosDs : MatTableDataSource<Usuario>;
  ngOnInit(){
    this.usuarios = getRouteData(this.route);
    this.usuariosDs = new MatTableDataSource(this.usuarios);
    console.log(this.usuarios)
  }

  toggleUsuarioAtivo(ativo: boolean, id: number){
    let url = ativo ? 'desativar-usuario' : 'ativar-usuario';
    this.svc.call(url, {id}).subscribe({
      next: r => {
        let u = this.usuarios.find(u => u.id == id);
        u!.ativo = !u!.ativo;
      },
      error: e => {console.log(e)}
    })
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-termos',
  standalone: true,
  imports: [],
  templateUrl: './termos.component.html',
  styleUrl: './termos.component.scss'
})
export class TermosComponent {

}

import { Pipe, PipeTransform } from "@angular/core";
import { categoriasBens } from "./categorias-bens";

@Pipe({
    name: 'toCategoria',
    standalone: true
})
export class CategoriaPipe implements PipeTransform{
    transform(value: any, ...args: any[]) {
        return categoriasBens.find(categoria => categoria.id == value)!['name'];
    }
}
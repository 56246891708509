import {  Injectable } from '@angular/core';
import { Usuario } from '../models/Usuario';
import { SelectOption } from '../common/select/select.component';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor() { }

  usuario: Usuario | undefined | null = undefined;
  corretoras: SelectOption[];
  tickers : string[];
  mensagensGlobais: string[];
  loaded = false;
}

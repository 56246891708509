import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../services/app.service';
import { InputAltComponent } from '../../../common/input-alt/input-alt.component';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';

@Component({
  selector: 'app-renda-fixa-dialog',
  standalone: true,
  imports: [InputAltComponent, ReactiveFormsModule, MatDialogModule],
  templateUrl: './renda-fixa-dialog.component.html',
  styleUrl: './renda-fixa-dialog.component.scss'
})
export class RendaFixaDialogComponent implements OnInit{
  constructor(public app: AppService, private builder:FormBuilder){}

  form:FormGroup;
  ngOnInit(): void {
    this.form = this.builder.group({
      nome: [],
      valor: [],
      id_corretora: [this.app.corretoras[0].id]
    })
  }
}

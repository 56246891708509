import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { getRouteData } from '../../../common/route-utils';
import { CommonModule } from '@angular/common';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Ativo } from '../../../models/Ativo';
import { AtivosCardComponent } from './ativos-card/ativos-card.component';
import { SelectComponent, SelectOption } from '../../../common/select/select.component';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { Subscription } from 'rxjs';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { colorSchemeVarious } from '../../../common/chartColorScheme';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-ativos',
  standalone: true,
  imports: [CommonModule, MatTableModule, AtivosCardComponent, SelectComponent, ReactiveFormsModule, NgxChartsModule, MatSortModule, MatIconModule, RouterModule],
  templateUrl: './ativos.component.html',
  styleUrl: './ativos.component.scss'
})
export class AtivosComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  constructor(private route: ActivatedRoute, private builder: FormBuilder) { }
  ativos: {};
  corretoras: string[];
  corretoraSelecionada: string;
  corretorasOptions: SelectOption[];
  dsAtivos: MatTableDataSource<Ativo>;
  form: FormGroup;
  sub: Subscription;
  private sort: MatSort;


  colunas = ['ticker', 'classe', 'setor', 'cotacao', 'preco_medio', 'lucro_prej', 'rentabilidade', 'quantidade', 'custo_ativo', '%', 'valor_atual'];
  colorSchemeVarious = colorSchemeVarious;
  ativosColor = {
    'Ação': 'text-blue-500',
    'FII': 'text-orange-500',
    'BDR': 'text-red-500',
    'ETF': 'text-green-500'
  }

  setDataSourceAttributes() {
    if (this.dsAtivos) this.dsAtivos.sort = this.sort;
  }

  ngOnInit(): void {
    const r = getRouteData(this.route); //r contendo ['corretoras'] e ['totais']
    
    if (r) {
      this.ativos = r['corretoras'];
      this.separaAtivosCorretora();
      this.corretoraChanged();
    }
    console.log(getRouteData(this.route))
  }

  private corretoraChanged() {
    this.sub = this.form.valueChanges.subscribe(
      v => {
        this.corretoraSelecionada = v['corretora'];
        console.log(this.ativos[this.corretoraSelecionada]);
        this.dsAtivos = new MatTableDataSource(this.ativos[this.corretoraSelecionada]['ativos']);
        this.dsAtivos.sort = this.sort;
      }
    );
  }

  private separaAtivosCorretora() {
    this.corretoras = Object.keys(this.ativos);
    this.corretoraSelecionada = this.corretoras[0];
    this.corretorasOptions = this.corretoras.map(corretora => { return { nome: corretora, id: corretora }; });
    this.dsAtivos = new MatTableDataSource(this.ativos[this.corretoraSelecionada]['ativos']);
    this.dsAtivos.sort = this.sort;
    this.form = this.builder.group({
      corretora: [this.corretoras[0]]
    });
  }

  ngOnDestroy(): void {
    if (this.sub) this.sub.unsubscribe();
  }
}

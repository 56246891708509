import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Movimentacao } from '../../../models/Movimentacao';
import { getRouteData } from '../../../common/route-utils';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { CommonModule } from '@angular/common';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { AdicionarMovimentacaoComponent } from './adicionar-movimentacao/adicionar-movimentacao.component';
import { BackendService } from '../../../services/backend.service';
import { MatIconModule } from '@angular/material/icon';
import { ConfirmacaoComponent } from '../../../common/confirmacao/confirmacao.component';
import { InputComponent } from '../../../common/input/input.component';
import { InputAltComponent } from '../../../common/input-alt/input-alt.component';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectComponent } from "../../../common/select/select.component";
import { tiposMovimentacao } from '../../../common/tiposMovimentacao';
import { AppService } from '../../../services/app.service';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
  selector: 'app-movimentacoes',
  standalone: true,
  imports: [MatTableModule, MatSortModule, CommonModule, MatIconModule, InputComponent, InputAltComponent,
    ReactiveFormsModule, FormsModule, SelectComponent, MatExpansionModule],
  templateUrl: './movimentacoes.component.html',
  styleUrl: './movimentacoes.component.scss'
})
export class MovimentacoesComponent implements OnInit {
  constructor(private route: ActivatedRoute, private dialog: MatDialog, private svc: BackendService, private router: Router, private builder: FormBuilder, public app: AppService) { }

  colunas = ['selecao', 'data', 'movimentacao', 'ativo', 'corretora', 'quantidade', 'preco', 'total']
  idsSelecionados = new Set<number>();
  movimentacoes: Movimentacao[];
  dsMovimentacoes: MatTableDataSource<Movimentacao>;
  toggleTodasLinhas = false;
  filtro = '';
  filtros: FormGroup;
  tiposMovimentacao = tiposMovimentacao;
  lastIndex;


  ngOnInit(): void {
    this.movimentacoes = getRouteData(this.route);
    this.filtros = this.builder.group({
      data: [''],
      movimentacao: [],
      ativo: [''],
      corretora: [],
      quantidade: [''],
      preco: [''],
      total: ['']
    })

    console.log(this.movimentacoes)
    this.dsMovimentacoes = new MatTableDataSource(this.movimentacoes);
    this.dsMovimentacoes.filterPredicate = (data, filter) => {
      const filtros = this.filtros.getRawValue();
      return data.ticker.toLowerCase().includes(filtros['ativo'].trim().toLowerCase())
        && data.data.toLowerCase().includes(filtros['data'].trim().toLowerCase())
        && filtros['movimentacao'].some(m => data.movimento == m)
        && filtros['corretora'].some(m => data.id_corretora == m)
        && data.quantidade?.toString().includes(filtros['quantidade'].trim().toLowerCase())
        && data.preco_unitario?.toString().includes(filtros['preco'].trim().toLowerCase())
        && data.valor_operacao?.toString().includes(filtros['total'].trim().toLowerCase())
    }
  }

  filtrar() {
    this.idsSelecionados = new Set();
    this.dsMovimentacoes.filter = this.filtros.getRawValue();
  }

  limparFiltros() {
    this.filtros.patchValue({
      data: '', ativo: '', quantidade: '', preco: '', total: '',
      movimentacao: this.tiposMovimentacao.map(m => m.id), corretora: this.app.corretoras.map(c => c.id)
    })
    this.filtrar();
  }

  adicionarLancamento() {
    const sub = this.dialog.open(AdicionarMovimentacaoComponent).afterClosed().subscribe(
      r => this.atualizarMovimentacoes()
    )
  }

  editarMovimentacoes() {
    let movimentacoesParaEditar: any[] = this.movimentacoes.filter(m => Array.from(this.idsSelecionados).includes(m.id!));
    const sub = this.dialog.open(AdicionarMovimentacaoComponent, { data: movimentacoesParaEditar }).afterClosed().subscribe(
      r => this.atualizarMovimentacoes()
    )
  }

  atualizarMovimentacoes() {
    let sub = this.svc.call<Movimentacao[]>('movimentacoes').subscribe(
      m => {
        this.movimentacoes = m;
        this.dsMovimentacoes.data = m;
        sub.unsubscribe()
      });
  }

  filtrarCorretora() {
  }

  excluirMovimentacoes() {
    let movimentacoesParaExcluir: number[] = Array.from(this.idsSelecionados);
    this.dialog.open(ConfirmacaoComponent, { data: { titulo: 'Excluir Movimentações', mensagem: 'Tem certeza que deseja excluir estas movimentações?' } }).afterClosed().subscribe(
      confirma => {
        if (confirma)
          this.svc.call('excluir-movimentacoes', movimentacoesParaExcluir).subscribe(
            r => {
              this.atualizarMovimentacoes();
              this.idsSelecionados = new Set();
    })})
  }

  selecionaLinha(id, index, $event) {
    if (this.lastIndex != null && $event.shiftKey) { // Quando usar shift, seleciona o intervalo de linhas
      let [inicio, fim] = this.lastIndex < index ? [this.lastIndex, index] : [index, this.lastIndex]; // Logica para funcionar intervalo subindo ou descendo
      for (let i = inicio + 1; i < fim; i++) {
        const id = this.dsMovimentacoes.data[i].id!;
        this.idsSelecionados.has(id) ? this.idsSelecionados.delete(id) : this.idsSelecionados.add(id);
      }
    }
    this.idsSelecionados.has(id) ? this.idsSelecionados.delete(id) : this.idsSelecionados.add(id)
    this.lastIndex = index;
  }

  toggleSelecionarTodas() {
    this.toggleTodasLinhas ? this.idsSelecionados.clear() : this.dsMovimentacoes.filteredData.forEach((m, index) => this.idsSelecionados.add(m.id!));
    this.toggleTodasLinhas = !this.toggleTodasLinhas;
  }

}

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { getRouteData } from '../../../common/route-utils';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { Subscription } from 'rxjs';
import { SelectComponent, SelectOption } from '../../../common/select/select.component';
import { Ativo } from '../../../models/Ativo';
import { CommonModule } from '@angular/common';
import { Color, NgxChartsModule, ScaleType } from '@swimlane/ngx-charts';
import { AtivosCardComponent } from '../ativos/ativos-card/ativos-card.component';
import {colorSchemeYellow, colorSchemeCyan, colorSchemeDonut, colorSchemeGreen} from '../../../common/chartColorScheme';

@Component({
  selector: 'app-dividendos',
  standalone: true,
  imports: [CommonModule, MatTableModule, AtivosCardComponent, SelectComponent, ReactiveFormsModule, NgxChartsModule, MatSortModule ],
  templateUrl: './dividendos.component.html',
  styleUrl: './dividendos.component.scss'
})
export class DividendosComponent  implements OnInit, OnDestroy{
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    if(this.dsDividendos) this.dsDividendos.sort = this.sort;
  }

  constructor(private route: ActivatedRoute, private builder: FormBuilder) { }
  ngOnDestroy(): void {
  }



  colorSchemeYellow = colorSchemeYellow;
  colorSchemeCyan = colorSchemeCyan;
  colorSchemeDonut = colorSchemeDonut;
  colorSchemeGreen = colorSchemeGreen;
  corretoras: string[];
  corretoraSelecionada: string;
  corretorasOptions: SelectOption[];
  dsDividendos: MatTableDataSource<Ativo>;
  form: FormGroup;
  sub : Subscription;
  private sort: MatSort;
  colunas = ['ticker', 'classe', 'setor', 'preco_unitario', 'total', 'data'];

  dividendosColor = {
    'Ação': 'text-blue-500',
    'FII': 'text-orange-500',
    'BDR': 'text-red-500',
    'ETF': 'text-green-500'
  }
  dividendos : any[];

  ngOnInit(): void {
    this.dividendos = getRouteData(this.route)['corretoras'];
    if (this.dividendos) {
      this.separaDividendosCorretora();
      this.corretoraChanged();
    }
    console.log(getRouteData(this.route))
  }


  private corretoraChanged() {
    this.sub = this.form.valueChanges.subscribe(
      v => {
        this.corretoraSelecionada = v['corretora'];
        console.log(this.dividendos[this.corretoraSelecionada]);
        this.dsDividendos = new MatTableDataSource(this.dividendos[this.corretoraSelecionada]['movimentacoes']);
        this.dsDividendos.sort = this.sort;
      }
    );
  }

  private separaDividendosCorretora() {
    this.corretoras = Object.keys(this.dividendos);
    this.corretoraSelecionada = this.corretoras[0];
    this.corretorasOptions = this.corretoras.map(corretora => { return { nome: corretora, id: corretora }; });
    this.dsDividendos = new MatTableDataSource(this.dividendos[this.corretoraSelecionada]['movimentacoes']);
    console.log(this.dsDividendos)
    this.dsDividendos.sort = this.sort;
    this.form = this.builder.group({
      corretora: [this.corretoras[0]]
    });
  }

}

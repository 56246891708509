<div [class]="'flex flex-col min-w-[240px] ' + class">
    <div class="bg-white rounded-t-lg px-10 py-5 flex items-center flex-col">
        <div class="text-primary fonte-3">{{topTitle}}</div>
        <div class="text-blue-500 fonte-5 font-semibold -mt-1 flex espaco-1">
            {{topFormated}}
        </div>
    </div>
    <div class="primary rounded-b-lg p-2">
        <div class="flex justify-center espaco-1 fonte-1">
            <div [class]="+bottomValue >= 0 ? 'text-green' : 'text-red'">{{+bottomValue > 0 ? '+' + bottomValue  : bottomValue }}%</div>
            <div class="text-white">{{bottomText}}</div>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { getRouteData } from '../../common/route-utils';
import { ActivatedRoute } from '@angular/router';
import { AtivosCorretora } from '../../models/AtivosCorretora';
import { AtivosCardComponent } from '../ativos-brasil/ativos/ativos-card/ativos-card.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatIconModule } from '@angular/material/icon';
import { CardInvestimentosBens, CardInvestimentosBensComponent } from './card-investimentos-bens/card-investimentos-bens.component';
import { colorSchemeGreen } from '../../common/chartColorScheme';


export interface Dashboard{
  ativos: {[nomeCorretora: string] : AtivosCorretora}  ;
}
@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [AtivosCardComponent, NgxChartsModule, MatIconModule, CardInvestimentosBensComponent],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit{
  
  constructor(private route: ActivatedRoute){}

  dashboard : Dashboard;
  cardInvestimentos: CardInvestimentosBens;
  cardBens: CardInvestimentosBens;
  colorSchemeGreen = colorSchemeGreen;
  
  ngOnInit(): void {
    this.dashboard = getRouteData(this.route);
    console.log(this.dashboard)
    const a = {};
    

    const totais = this.dashboard['ativos']['totais'];
    console.log(totais['total_ticker'])
    this.cardInvestimentos = {
      card1: {titulo: 'Investimentos Brasil', historico: [{name: 'Ações', value: totais['total_acoes']}, {name: 'Fundos Imobiliários', value: totais['total_fiis']}], 
      grafico: [{name: 'Ações', value: totais['total_acoes']/totais['total_investido']}, {name: 'FIIs', value: totais['total_fiis']/totais['total_investido']}]},
      card2: {titulo: 'Exposição', grafico: totais['total_ticker']},
      card3: {titulo: 'Investimento por Classe', grafico: [{name: 'Ações' , value: totais['total_acoes']},{ name: 'FII\'s', value: totais['total_fiis']}]}
    }

    this.cardBens = {
      card1: {titulo: 'Bens', historico: [{name: 'HB20', value: 56000}, {name: 'Terreno Sumaré', value: 200000}], 
      grafico: null},
      card2: {titulo: 'Disposição', grafico: totais['total_ticker']},
      card3: {titulo: 'Bens por Classe', grafico: [{name: 'Ações' , value: totais['total_acoes']},{ name: 'FII\'s', value: totais['total_fiis']}]}
    }
    
  }

  
}

import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';


export interface SelectOption  {
  nome: string;
  id: any;
}
@Component({
  selector: 'app-select',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss',
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectComponent), multi: true}
  ]
})
export class SelectComponent implements ControlValueAccessor, OnInit,  OnDestroy {
  constructor(private builder: FormBuilder){}

  @Input() label = '';
  @Input() options: SelectOption[];
  @Input() classSelect;
  @Input() classLabel;
  @Input() rounded = true;
  @Input() multiple = false;
  @Input() selecionaTodos = true;
  form: FormGroup;
  sub: Subscription;

  ngOnInit(): void {
    let defaultValue = this.options[0].id;
    if(this.multiple){
      defaultValue = this.selecionaTodos ? this.options.map(o => o.id) : [this.options[0].id];
    }

    this.form = this.builder.group({
      select: [defaultValue]
    });

    
    this.sub = this.form.valueChanges.subscribe(
      v => {
        this.multiple ? this.onChange(v['select']) : this.onChange(v['select'][0]);
      }
     )
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  onChange(val: any){}
  writeValue(val: any): void {
    if(val) this.form.get('select')?.setValue(val);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
    //Estou disparando esse onchange para notificar o pai que populamos o select multiple com todos os valores. Sem isso n funciona
    if(this.multiple && this.selecionaTodos)this.onChange(this.form.get('select')!.value); 
  }
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {}


}

<img id="stripes" src="assets/stripes.png" class="h-[400px] w-full fixed bottom-[-100px]">

<!-- <div class="h-[100px] border border-b-1 flex items-center justify-between px-[10vw]">

    <img src="assets/logo_inv.png" class="w-[200px]">
    <button 
    class="custom border border-1 border-[#072331] px-4 py-2 rounded-full fonte-3 text-gray-700 font-medium hover:opacity-60" 
    (click)="login()" >Fazer Login</button>
</div> -->

<div class="h-[calc(100vh-144px)] flex xl:flex-row flex-col-reverse">
    <div class="flex items-end  max-w-[60vw]  z-10">
        <img src="assets/home-tablet.png" class="h-auto" >
    </div>

    <div class="flex items-center justify-center gap-8 flex-col text-center xl:pb-20  h-full xl:-ms-[15vw]">
        <div class="font-nunito text-[calc(3.5vw+10px)] leading-[calc(3.5vw+10px)]">Invista com inteligência, <br><b>controle com facilidade</b></div>
        <div class=" max-w-[80%] text-[calc(1vw+10px)]">O seu parceiro ideal para uma gestão financeira inteligente e eficaz!
            <br>Nosso aplicativo foi cuidadosamente desenvolvido para atender às necessidades daqueles que buscam uma organização completa de seus investimentos e finanças pessoais.
        </div>
        <div class="flex xl:espaco-10 espaco-5">
                <button class="xl:fonte-3 xl:w-[200px] w-[160px] flex justify-center items-center espaco-3 custom border border-1 border-[#072331] py-2 rounded-full text-gray-700 font-medium hover:opacity-70">
                    <img src="assets/instagram_outline.png" width="20" >
                    Instagram
                </button>
            <button class="xl:fonte-3 xl:w-[200px] w-[160px] custom accent text-gray-400 py-2 rounded-full text-gray-700 font-medium hover:opacity-70">Experimente Grátis</button>
        </div>
    </div>

</div>


import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { InputComponent } from '../../../../common/input/input.component';
import { InputAltComponent } from '../../../../common/input-alt/input-alt.component';
import { SelectComponent, SelectOption } from '../../../../common/select/select.component';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AppService } from '../../../../services/app.service';
import { AutocompleteComponent } from '../../../../common/autocomplete/autocomplete.component';
import { BackendService } from '../../../../services/backend.service';

@Component({
  selector: 'app-adicionar-movimentacao',
  standalone: true,
  imports: [MatDialogModule, InputComponent, InputAltComponent, SelectComponent, ReactiveFormsModule, AutocompleteComponent],
  templateUrl: './adicionar-evento.component.html',
  styleUrl: './adicionar-evento.component.scss'
})
export class AdicionarEventoComponent implements OnInit {

  constructor(private builder: FormBuilder, public app: AppService, private svc: BackendService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.form = this.builder.group({
      data: [, Validators.required],
      movimento: ['Grupamento', Validators.required],
      ticker: ['', Validators.required],
      preco_unitario: [, Validators.required],
      valor_operacao: [, Validators.required],
    })
  }
  form: FormGroup;
  tipoMovimentacao: SelectOption[] = [
    { nome: 'Grupamento', id: 'Grupamento' },
    { nome: 'Desdobramento', id: 'Desdobramento' },
    { nome: 'Amortização', id: 'Amortização' },
    { nome: 'Bonificação', id: 'Bonificação' },


  ]

  adicionarMovimentacao() {
    const sub = this.svc.call('adicionar-movimentacao-global', this.form.getRawValue()).subscribe(
      {
        next: r => { sub.unsubscribe(); this.dialog.closeAll()},
        error: e => { console.log(e)}
      }
    )
  }

}

<div class="flex items-center justify-between">
    <app-ativos-card class="max-w-[300px]"  topTitle="Saldo em Renda Fixa" topValue="120.000" bottomText="de variação hoje" bottomValue="5%"/>
    <button class="flex espaco-3 accent">
        <mat-icon fontIcon="add font-bold"/>
        Adicionar Renda Fixa
    </button>
</div>


<div class="rounded-3xl bg-white p-6 mt-6">
    <app-renda-fixa-card />
</div>
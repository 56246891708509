<div mat-dialog-title>Sugestão</div>
<mat-dialog-content class="w-[300px] md:w-[600px] flex flex-col" [formGroup]="form">
    <div class="flex flex-col" *ngIf="step==1">
        <app-textarea placeholder="Sua sugestão" [rows]="6" formControlName="texto" [maxlength]="300"
            class="w-full" />
        <div class="text-end fonte-1 text-gray-400">{{form.get('texto')!.value.length}}/300</div>
        <div class="flex justify-end mt-4"><button class="accent rounded-full px-5" [disabled]="form.invalid"
                (click)="enviarSugestao()">Enviar</button></div>
    </div>

    <div *ngIf="step==2">
        <div class="text-center px-4 py-4 fonte-3 rounded">
            Sua sugestão foi enviada com sucesso!
            <br>Em breve entraremos em contato via e-mail.
        </div>
        <div class="flex justify-end"><button class="accent rounded-full px-5"
                (click)="dialog.closeAll()">OK</button></div>
    </div>
</mat-dialog-content>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { InputComponent } from '../../common/input/input.component';
import { MatIconModule } from '@angular/material/icon';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgxCaptchaModule } from 'ngx-captcha';
import { environment } from '../../../environments/environment';
import { BackendService } from '../../services/backend.service';
import { ValidaComplexidade, ValidaSenhasIguais } from '../../common/password-validator';
import { Router } from '@angular/router';
import { AppService } from '../../services/app.service';
import { Subscription } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [InputComponent, MatIconModule, ReactiveFormsModule, CommonModule, NgxCaptchaModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit, OnDestroy{
  constructor(private builder: FormBuilder, private svc: BackendService, private router: Router, private app: AppService, private keycloak: KeycloakService){}
  ngOnInit(): void {
    if(this.app.usuario) this.router.navigate(['dashboard']);
    this.inicializaForms();
  }

  step = 'login';
  siteKey = environment.RECAPTCHA_KEY;
  formLogin: FormGroup;
  formRegistro: FormGroup;
  formResetSenha: FormGroup;
  subs: Subscription[] = [];

  private inicializaForms() {
    this.formLogin = this.builder.group({
      email: ['', [Validators.required, Validators.email]],
      senha: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(16), ValidaComplexidade]],
    });

    this.formRegistro = this.builder.group({
      email: ['', [Validators.required, Validators.email]],
      senha: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(16), ValidaComplexidade]],
      confirmaSenha: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(16)]],
      captcha_response: ['', [Validators.required]],
    });
    this.formRegistro.addValidators(ValidaSenhasIguais);
  }

  login(){
    this.keycloak.login().then( r=> console.log('voltando', r))
    // this.subs.push(this.svc.call<any>('login', this.formLogin.getRawValue()).subscribe({
    //   next: r => {
    //     if(r) {
    //       console.log(r); 
    //       this.app.usuario = r['usuario'];
    //       this.app.tickers = r['tickers'];
    //       this.app.corretoras = r['corretoras'];
    //       this.router.navigate(['dashboard'])
    //     }
    //   },
    //   error: e => {console.log(e)}
    // }))
  }

  registraUsuario(){
    this.subs.push(this.svc.call<any>('registro', this.formRegistro.getRawValue()).subscribe({
      next: r => {
        console.log(r);
        this.app.usuario = r;
        this.router.navigate(['dashboard'])


      },
      error: e => {console.log(e)}
    }))
  }

  ngOnDestroy(){
    this.subs.forEach(sub => sub.unsubscribe());
  }
  
  
  teste(){
    console.log(this.keycloak.isLoggedIn());
    this.svc.call('app-config').subscribe(
      r => console.log(r)
    )   
  }
}
<form class="flex flex-col">
  <label [class]="classLabel" for="input">{{label}}</label>
  <input type="text" id="input" #input matInput [placeholder]="placeholder" [formControl]="control"
    [matAutocomplete]="auto" [class]="class" (input)="filter()" (focus)="filter()" (blur)="checkEmpty($event)">
  <mat-autocomplete #auto="matAutocomplete" requireSelection (optionSelected)="onChange($event.option.value)">
    <!-- <mat-option value="" *ngIf="includeNull"></mat-option> -->
    @for (option of filteredOptions; track option) {
    <mat-option [value]="option">{{option}}</mat-option>
    }
  </mat-autocomplete>
</form>
<div class="flex flex-col espaco-5">
    @for (sugestao of sugestoes; track $index) {
    <div class="flex justify-between py-4 px-6 shadow rounded-3xl bg-green-400 hover:outline outline-gray-400" [class.lida]="sugestao.lida" [class.hidden]="sugestao.visivel == false">
        <div class="flex flex-col">
            <div class="flex espaco-3 fonte-1 mb-2 text-gray-500">
                {{sugestao.usuario.email}} | {{sugestao.criacao}}
            </div>

            <div class="text-[15x] font-semibold text-gray-600">{{sugestao.texto}}</div>
 
        </div>

        <div class="flex espaco-3 items-center p-2">
            <mat-icon fontIcon="delete" class="text-primary hover:text-gray-500 cursor-pointer !w-[30px] !h-[30px] text-3xl" (click)="abrirConfirmacao(sugestao.id!)"/>
            <mat-icon fontIcon="task_alt" class="text-primary hover:text-gray-500 cursor-pointer !w-[30px] !h-[30px] text-3xl" (click)="lerSugestao(sugestao.id!)" *ngIf="!sugestao.lida"/>
        </div>
    </div>
    }
</div>